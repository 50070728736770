import Vue from 'vue'
import Zendesk from '@dansmaculotte/vue-zendesk'

const KEY = '8ca75786-0cd5-410a-a4cf-e1633ec28efa'
const DISABLED = false
const HIDE_ON_LOAD = true
const SETTINGS = {}

export default function (context) {
  Vue.use(Zendesk, {
    key: KEY,
    disabled: DISABLED,
    hideOnLoad: HIDE_ON_LOAD,
    settings: SETTINGS
  })
}
