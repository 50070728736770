import { nanoid } from 'nanoid'

/**
 * Enqueue a Luro job
 * Puts an optimistic job in the store, then makes the network
 * request, then adds the real job and removes the optimistic job
 *
 * @function
 * @name enqueueJob
 * @memberof module:helpers/jobs
 *
 * @param {String} type Job type
 * @param {String} endpoint API endpoint for network request
 * @param {String} payload API call parameters
 * @returns {Object} Style/CSS object
 */
export const enqueueJob = async (
	{ type, endpoint, payload },
	{ store, axios, auth }
) => {
	const optimisticJob = {
		id: nanoid(),
		type,
		status: 'PENDING',
		teamId: auth.$storage.getCookie('currentTeam'),
		userId: auth.user.id,
		updatedAt: new Date(),
		createdAt: new Date(),
		statusMessages: []
	}
	try {
		store.commit('jobTickets/add', optimisticJob)
		const job = await axios.$post(endpoint, payload)

		store.commit('jobTickets/addRemove', {
			addJob: job,
			removeJob: optimisticJob
		})

		return job
	} catch (error) {
		store.commit('jobTickets/remove', optimisticJob)
		throw error
	}
}

export default ({ app, store, $axios }, inject) => {
	inject('enqueueJob', (params) =>
		enqueueJob(params, { store, axios: $axios, auth: app.$auth })
	)
}
