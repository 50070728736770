
export default {
	props: {
		title: {
			type: String,
			default: null
		},
		content: {
			type: String,
			default: null
		},
		action: {
			type: Object,
			default: () => {}
		},
		type: {
			type: String,
			default: null,
			validator(value) {
				return ['success', 'error', 'billing'].includes(value)
			}
		},
		autodismiss: {
			type: Boolean,
			default: true
		},
		isDismissable: {
			type: Boolean,
			default: true
		},
		showIcon: {
			type: Boolean,
			default: true
		},
		inline: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isDismissed: false
		}
	},
	mounted() {
		if (this.autodismiss) {
			setTimeout(this.dismissAlert, 5000)
		}
	},
	methods: {
		dismissAlert() {
			this.isDismissed = true
			this.$nuxt.$emit('dismissAlert')
		}
	}
}
