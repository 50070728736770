import { isWithinMinutes } from '~/plugins/formatDate'

export const state = () => ({
	jobs: []
})

export const mutations = {
	initialize(state, jobs) {
		// add jobs to state.jobs but retain any jobs that don't exist by id in the new list, so we don't lose any optimistic jobs
		state.jobs = [
			...jobs,
			...state.jobs.filter((job) => !jobs.some((j) => j.id === job.id))
		]

		// sort by updatedAt
		state.jobs.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
	},
	add(state, job) {
		state.jobs.push(job)
	},
	addRemove(state, { addJob, removeJob }) {
		state.jobs = state.jobs
			.filter((j) => j.id !== removeJob.id)
			.concat([addJob])
	},
	update(state, job) {
		const newlist = state.jobs.filter((j) => j.id !== job.id)
		state.jobs = [...newlist, job]
	},
	remove(state, job) {
		state.jobs = state.jobs.filter((j) => j.id !== job.id)
	}
}

export const actions = {
	async fetch() {
		const jobs = await this.$axios.$get(`/api/jobs`)
		this.commit('jobTickets/initialize', jobs)
	}
}

export const getters = {
	getJobs: (state) => {
		return state.jobs
	},
	getJobsByType: (state) => (type) => {
		return state.jobs.filter((job) => job.type === type)
	},
	getJobsByStatus: (state) => (status) => {
		return state.jobs.filter((job) => job.status === status)
	},
	openJobsByType: (state) => (type) => {
		return state.jobs.filter(
			(job) =>
				!['FAILED', 'COMPLETED'].includes(job.status) && job.type === type
		)
	},
	cleanJobsByType: (state) => (type) => {
		const jobs = state.jobs
			.map((job) => {
				return {
					...job,
					latestStatusMessage: job.statusMessages?.length
						? job.statusMessages[job.statusMessages?.length - 1]?.slice(
								job.statusMessages[job.statusMessages?.length - 1]?.indexOf(' ')
						  )
						: null,
					...job.payload
				}
			})
			.filter(
				(job) =>
					isWithinMinutes(job.updatedAt, 30) &&
					job.type === type &&
					job.dismissed === false
			)

		return jobs
	}
}
