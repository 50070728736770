const types = {
	STYLE: { plural: 'Styles', singular: 'Style', route: '/styles' },
	STYLES: { plural: 'Styles', singular: 'Style', route: '/styles' },
	COMPONENT: {
		plural: 'Components',
		singular: 'Component',
		route: '/components'
	},
	COMPONENTS: {
		plural: 'Components',
		singular: 'Component',
		route: '/components'
	},
	DESIGN: {
		plural: 'Design components',
		singular: 'Design component',
		route: '/components'
	},
	CODE: {
		plural: 'Code components',
		singular: 'Code component',
		route: '/components'
	},
	PAGE: { plural: 'Pages', singular: 'Page', route: '/pages' },
	PAGES: { plural: 'Pages', singular: 'Page', route: '/pages' },
	PROJECT: { plural: 'Projects', singular: 'Project', route: '/projects' },
	PROJECTS: { plural: 'Projects', singular: 'Project', route: '/projects' },
	PROTOTYPE: {
		plural: 'Prototypes',
		singular: 'Prototype',
		route: '/prototypes'
	},
	PROTOTYPES: {
		plural: 'Prototypes',
		singular: 'Prototype',
		route: '/prototypes'
	},
	RESEARCH: { plural: 'Research', singular: 'Research', route: '/research' },
	USERTEST: { plural: 'Testing', singular: 'Test', route: '/testing' },
	TESTING: { plural: 'Testing', singular: 'Test', route: '/testing' },
	PERFORMANCE: {
		plural: 'Performance',
		singular: 'Performance',
		route: '/performance'
	},
	ACCESSIBILITY: {
		plural: 'Accessibility',
		singular: 'Accessibility',
		route: '/accessibility'
	},
	ANALYTICS: {
		plural: 'Analytics',
		singular: 'Analytics',
		route: '/analytics'
	},
	DOCUMENTATION: {
		plural: 'Documentation',
		singular: 'Documentation',
		route: '/documentation'
	},
	FIGMA_ANALYTICS: {
		plural: 'Components',
		singular: 'Component',
		route: '/components'
	},
	UPDATE_FIGMA_IMAGES: {
		plural: 'Components',
		singular: 'Component',
		route: '/components'
	},
	COMPONENT_TRACKING: {
		plural: 'Components',
		singular: 'Component',
		route: '/components'
	}
}

export const typeToRoute = (arg, isPublic, shareId) => {
	if (isPublic) {
		return `/share/${shareId}${types[arg]?.route || ''}`
	}

	return types[arg]?.route || '/'
}

export const typeToSectionSpecificRoute = (
	type,
	section,
	isPublic,
	shareId
) => {
	if (isPublic) {
		return `/share/${shareId}${types[section]?.route || ''}`
	}

	switch (type) {
		case 'DOCUMENTATION': {
			return `${types[section]?.route || '/'}?tab=documentation`
		}
		default: {
			return types[section]?.route || '/'
		}
	}
}

export const typeToHumanizedPlural = (arg) => {
	return types[arg]?.plural || 'Items'
}

export const typeToHumanizedSingular = (arg) => {
	return types[arg]?.singular || 'Item'
}

export const isColor = (item) => {
	return ['color', 'colors', 'gradient'].includes(
		item.properties?.token?.extensions['org.luro']?.category
	)
}

export const isEffect = (item) => {
	return ['effect', 'effects'].includes(
		item.properties?.token?.extensions['org.luro']?.category
	)
}

export const isFont = (item) => {
	return ['font', 'typography'].includes(
		item.properties?.token?.extensions['org.luro']?.category
	)
}

export const jobTypeToName = (type) => {
	switch (type) {
		case 'PERFORMANCE':
			return 'Lighthouse'
		case 'ACCESSIBILITY':
			return 'Lighthouse'
		case 'ANALYTICS':
			return 'Google Analytics'
		case 'FIGMA_ANALYTICS':
			return 'Figma Analytics'
		case 'UPDATE_FIGMA_IMAGES':
			return 'Figma Images'
		case 'COMPONENT_TRACKING':
			return 'Component Tracking'
		default:
			return 'Job'
	}
}

export const jobTypeToTitle = (type) => {
	switch (type) {
		case 'PERFORMANCE':
			return 'Lighthouse Report'
		case 'ANALYTICS':
			return 'Google Analytics'
		case 'FIGMA_ANALYTICS':
			return 'Figma Analytics'
		case 'UPDATE_FIGMA_IMAGES':
			return 'Update Figma Thumbnails'
		case 'COMPONENT_TRACKING':
			return 'Component Tracking'
		default:
			return 'Job'
	}
}

export default ({ app }, inject) => {
	inject('types', types)
	inject('typeToRoute', typeToRoute)
	inject('typeToHumanizedPlural', typeToHumanizedPlural)
	inject('typeToHumanizedSingular', typeToHumanizedSingular)
	inject('typeToSectionSpecificRoute', typeToSectionSpecificRoute)
	inject('isColor', isColor)
	inject('isFont', isFont)
	inject('isEffect', isEffect)
	inject('jobTypeToTitle', jobTypeToTitle)
	inject('jobTypeToName', jobTypeToName)
}
