const toolsList = [
	{
		test: /acrobat.adobe/,
		icon: '/file-icons/full-color/acrobat.svg',
		name: 'Acrobat'
	},
	{ test: /.pdf/, icon: '/file-icons/full-color/acrobat.svg', name: 'Acrobat' },
	{
		test: /xd.adobe/,
		icon: '/file-icons/full-color/adobexd.svg',
		name: 'Adobe XD'
	},
	{
		test: /atlassian/,
		icon: '/file-icons/full-color/atlassian.svg',
		name: 'Atlassian'
	},
	{
		test: /bitbucket/,
		icon: '/file-icons/full-color/bitbucket.svg',
		name: 'Bitbucket'
	},
	{
		test: /confluence/,
		icon: '/file-icons/full-color/confluence.svg',
		name: 'Confluence'
	},
	{
		test: /figma.com/,
		icon: '/file-icons/full-color/figma.svg',
		name: 'Figma'
	},
	{
		test: /drive.google/,
		icon: '/file-icons/full-color/googledrive.svg',
		name: 'Drive'
	},
	{
		test: /optimize.google.com/,
		icon: '/file-icons/full-color/googleoptimize.svg',
		name: 'Optimize'
	},
	{
		test: /docs.google.com\/spreadsheets/,
		icon: '/file-icons/full-color/googlesheets.svg',
		name: 'Sheets'
	},
	{
		test: /docs.google.com\/document/,
		icon: '/file-icons/full-color/googledocs.svg',
		name: 'Docs'
	},
	{
		test: /docs.google.com\/presentation/,
		icon: '/file-icons/full-color/googleslides.svg',
		name: 'Slides'
	},
	{
		test: /analytics.google/,
		icon: '/file-icons/full-color/googleanalytics.svg',
		name: 'Analytics'
	},
	{
		test: /hotjar/,
		icon: '/file-icons/full-color/hotjar.svg',
		name: 'Hotjar'
	},
	{
		test: /invision/,
		icon: '/file-icons/full-color/invision.svg',
		name: 'Invision'
	},
	{
		test: /jira/,
		icon: '/file-icons/full-color/jirasoftware.svg',
		name: 'Jira'
	},
	{
		test: /maze.co/,
		icon: '/file-icons/full-color/maze.svg',
		name: 'Maze'
	},
	{
		test: /mailchimp/,
		icon: '/file-icons/full-color/mailchimp.svg',
		name: 'Mailchimp'
	},
	{
		test: /marvelapp.com/,
		icon: '/file-icons/full-color/marvel.svg',
		name: 'Marvel'
	},
	{
		test: /miro.co/,
		icon: '/file-icons/full-color/miro.svg',
		name: 'Miro'
	},
	{
		test: /excel/,
		icon: '/file-icons/full-color/microsoftexcel.svg',
		name: 'Excel'
	},
	{
		test: /.xls/,
		icon: '/file-icons/full-color/microsoftexcel.svg',
		name: 'Excel'
	},
	{
		test: /onenote/,
		icon: '/file-icons/full-color/microsoftonenote.svg',
		name: 'OneNote'
	},
	{
		test: /powerpoint/,
		icon: '/file-icons/full-color/microsoftpowerpoint.svg',
		name: 'Powerpoint'
	},
	{
		test: /.ppt/,
		icon: '/file-icons/full-color/microsoftpowerpoint.svg',
		name: 'Powerpoint'
	},
	{
		test: /sharepoint/,
		icon: '/file-icons/full-color/microsoftsharepoint.svg',
		name: 'Sharepoint'
	},
	{
		test: /docx/,
		icon: '/file-icons/full-color/microsoftword.svg',
		name: 'Word'
	},
	{
		test: /penpot.app/,
		icon: '/file-icons/full-color/penpot.svg',
		name: 'Penpot'
	},
	{
		test: /github.com/,
		icon: '/file-icons/full-color/github.svg',
		name: 'Github'
	},
	{
		test: /slack.com/,
		icon: '/file-icons/full-color/slack.svg',
		name: 'Slack'
	},
	{
		test: /salesforce/,
		icon: '/file-icons/full-color/salesforce.svg',
		name: 'Salesforce'
	},
	{
		test: /shopify/,
		icon: '/file-icons/full-color/shopify.svg',
		name: 'Shopify'
	},
	{
		test: /sketch/,
		icon: '/file-icons/full-color/sketch.svg',
		name: 'Sketch'
	},
	{
		test: /usertesting/,
		icon: '/file-icons/full-color/usertesting.svg',
		name: 'UserTesting'
	},
	{
		test: /codepen/,
		icon: '/file-icons/full-color/codepen.svg',
		name: 'Codepen'
	},
	{
		test: /stackblitz/,
		icon: '/file-icons/full-color/stackblitz.svg',
		name: 'StackBlitz'
	},
	{
		test: /storybook/,
		icon: '/file-icons/full-color/storybook.svg',
		name: 'Storybook'
	}
]

/**
 *
 * @param {String} url - Third-party service or hosted asset URL
 * @param {Boolean} isLink - If we already know it's a link and not a file
 * @param {String} name - Name of name
 */
export const tools = function (url, isLink = true) {
	const toolExists = toolsList.find((tool) => tool.test.test(url))
	if (toolExists) return toolExists

	return isLink
		? { icon: '/file-icons/link.svg', name: 'External Link' }
		: { icon: '/file-icons/generic.svg', name: 'External Link' }
}

export const entityToIcon = function ({ type, url }) {
	if (url) {
		const toolExists = toolsList.find((tool) => tool.test.test(url))

		if (toolExists) {
			return toolExists
		}
	}

	switch (type) {
		case 'PAGE': {
			return { icon: '/file-icons/full-color/page-crawler.svg', name: 'Page' }
		}
		case 'DOCUMENTATION': {
			return { icon: '/file-icons/pen.svg', name: 'Documentation' }
		}
		case 'PROJECT': {
			return { icon: '/file-icons/folder.svg', name: 'Project' }
		}
		case 'STYLE': {
			return { icon: '/file-icons/full-color/figma.svg', name: 'Style' }
		}
		case 'RESEARCH': {
			return { icon: '/file-icons/full-color/insights.svg', name: 'Research' }
		}
		case 'USERTEST': {
			return { icon: '/file-icons/full-color/insights.svg', name: 'User test' }
		}
		case 'PROTOTYPE': {
			return { icon: '/file-icons/full-color/insights.svg', name: 'Prototype' }
		}
		case 'CODE': {
			return { icon: '/file-icons/full-color/code.svg', name: 'Code' }
		}
		case 'DESIGN': {
			return { icon: '/file-icons/full-color/page-crawler.svg', name: 'Design' }
		}
		case 'COMPONENT': {
			return {
				icon: '/file-icons/full-color/page-crawler.svg',
				name: 'Component'
			}
		}
		default: {
			return { icon: '/file-icons/link.svg', name: 'Link' }
		}
	}
}

export const jobTypeToIcon = function (jobType) {
	switch (jobType) {
		case 'PERFORMANCE': {
			return {
				icon: '/file-icons/full-color/googlelighthouse.svg',
				name: 'Lighthouse'
			}
		}
		case 'ANALYTICS': {
			return {
				icon: '/file-icons/full-color/googleanalytics.svg',
				name: 'Google Analytics'
			}
		}
		case 'FIGMA_ANALYTICS': {
			return {
				icon: '/file-icons/full-color/figma.svg',
				name: 'Figma Analytics'
			}
		}
		case 'PAGECRAWL': {
			return {
				icon: '/file-icons/full-color/page-crawler.svg',
				name: 'Page Crawler'
			}
		}
		case 'IMPORT_PAGES': {
			return {
				icon: '/file-icons/full-color/page-crawler.svg',
				name: 'Import Pages'
			}
		}
		case 'SCREENSHOT': {
			return {
				icon: '/file-icons/full-color/page-crawler.svg',
				name: 'Screenshots'
			}
		}
		case 'UPDATE_FIGMA_IMAGES': {
			return {
				icon: '/file-icons/full-color/figma.svg',
				name: 'Update Figma Images'
			}
		}
		case 'COMPONENT_TRACKING': {
			return {
				icon: '/file-icons/full-color/integrations.svg',
				name: 'Component Tracking'
			}
		}
		default: {
			return { icon: '/file-icons/generic.svg', name: 'Job' }
		}
	}
}

export default ({ app }, inject) => {
	inject('entityToIcon', entityToIcon)
	inject('jobTypeToIcon', jobTypeToIcon)
	inject('tools', tools)
}
