import capitalize from 'lodash.capitalize'
import logger from 'logger'

export const capitalizeFirstLetter = (string) => {
	return capitalize(string)
}

export const capitalizeFirstLetterOfEachWord = (string) => {
	if (!string) {
		return ''
	}

	return string
		.split(' ')
		.map((word) => capitalize(word))
		.join(' ')
}

export const cleanTokenName = (string) => {
	if (!string) {
		return ''
	}

	if (string.includes('/')) {
		return string.split('/').pop().trim()
	} else {
		return string
	}
}

export const alphaNullUndefinedFirst = (ascending = true) => {
	return function (a, b) {
		// equal items sort equally
		if (a === b) {
			return 0
		}

		// nulls sort first, before anything else, always
		if (a === null || a === 'null' || a === 'undefined' || a === '') {
			return -1
		}

		if (b === null || b === 'null' || b === 'undefined' || b === '') {
			return 1
		}

		// ascending, lowest first
		if (ascending) {
			return a < b ? -1 : 1
		}

		// descending, highest first
		return a < b ? 1 : -1
	}
}

export const getUserLabel = (user) => {
	if (!user) {
		return ''
	}

	if (user.first_name && user.last_name) {
		return user.first_name + ' ' + user.last_name
	} else if (user.first_name) {
		return user.first_name
	} else if (user.last_name) {
		return user.last_name
	} else {
		return user.email
	}
}

export const formatURL = (event) => {
	const paste = event.target.value

	// For sharepoint/onedrive iframe pastes
	if (
		/<iframe/.test(paste) &&
		(/sharepoint.com/.test(paste) || /onedrive.live.com/.test(paste))
	) {
		const src = paste.match(/src\s*=\s*"(.+?)"/)
		const cleanedSrc = src[1]
			.replaceAll('&amp;amp;', '&')
			.replaceAll('&amp;', '&')
		try {
			this.item[event.target.id] = cleanedSrc
		} catch (e) {
			// this is null in tests
		}
		return cleanedSrc
	}

	// For sharepoint/onedrive non-iframe pastes
	if (/sharepoint.com/.test(paste) || /onedrive.live.com/.test(paste)) {
		const cleanedSrc = paste.replaceAll('&amp;', '&')
		try {
			this.item[event.target.id] = cleanedSrc
		} catch (e) {
			// this is null in tests
		}

		return cleanedSrc
	}

	return paste
}

export const isStorybook = (url) => {
	return /story/.test(url) || /chromatic/.test(url) || /viewMode=docs/.test(url)
}

export const setupEmbedURL = (url, storybookType) => {
	try {
		if (isStorybook(url)) {
			// https://storybook.js.org/docs/react/sharing/embed
			const parsedURL = new URL(url)
			const searchParams = new URLSearchParams(parsedURL.search)
			const searchParamEntries = Object.fromEntries(searchParams.entries())
			const storyId = searchParamEntries.id
				? searchParamEntries.id
				: searchParamEntries.path.replace('/story/', '')
			let path =
				parsedURL.origin +
				(parsedURL.pathname === '/' || parsedURL.pathname === '/iframe.html'
					? ''
					: parsedURL.pathname.replace('/iframe.html', ''))
			path = path.endsWith('/') ? path : `${path}/`
			switch (storybookType) {
				case 'withoutToolbar':
					return `${path}iframe.html?id=${storyId}&viewMode=story&shortcuts=false&singleStory=true`
				case 'withToolbar':
					return `${path}?path=/story/${storyId}&full=1&shortcuts=false&singleStory=true`
				case 'withDocs':
					return `${path}iframe.html?id=${storyId}&viewMode=docs&shortcuts=false&singleStory=true`
				default:
					return url
			}
		} else {
			return url
		}
	} catch (error) {
		logger.error(error, {
			instance: 'nuxt-app',
			function: 'setupEmbedURL',
			url
		})
		return url
	}
}

export const storybookComponentLink = (url) => {
	try {
		if (isStorybook(url)) {
			// https://storybook.js.org/docs/react/sharing/embed
			const parsedURL = new URL(url)
			const searchParams = new URLSearchParams(parsedURL.search)
			const searchParamEntries = Object.fromEntries(searchParams.entries())
			const storyId = searchParamEntries.id
				? searchParamEntries.id
				: searchParamEntries.path.replace('/story/', '')
			let path =
				parsedURL.origin +
				(parsedURL.pathname === '/' || parsedURL.pathname === '/iframe.html'
					? ''
					: parsedURL.pathname.replace('/iframe.html', ''))
			path = path.endsWith('/') ? path : `${path}/`

			return `${path}?path=/story/${storyId}&full=1`
		} else {
			return url
		}
	} catch (error) {
		logger.error(error, {
			instance: 'nuxt-app',
			function: 'setupEmbedURL',
			url
		})
		return url
	}
}

export const getStorybookRootURL = (url) => {
	try {
		const parsedURL = new URL(url)

		return parsedURL.origin
	} catch (error) {
		logger.error(error, {
			instance: 'nuxt-app',
			function: 'getStorybookRootURL',
			url
		})
		return null
	}
}

export const getStorybookTypeOfURL = (url) => {
	try {
		if (isStorybook(url)) {
			if (url.endsWith('&viewMode=story&shortcuts=false&singleStory=true')) {
				return 'withoutToolbar'
			} else if (url.endsWith('&full=1&shortcuts=false&singleStory=true')) {
				return 'withToolbar'
			} else if (
				url.endsWith('&viewMode=docs&shortcuts=false&singleStory=true')
			) {
				return 'withDocs'
			}

			return null
		} else {
			return null
		}
	} catch (error) {
		logger.error(error, {
			instance: 'nuxt-app',
			function: 'getStorybookTypeOfURL',
			url
		})
		return null
	}
}

export default ({ app }, inject) => {
	inject('capitalizeFirstLetter', capitalizeFirstLetter)
	inject('capitalizeFirstLetterOfEachWord', capitalizeFirstLetterOfEachWord)
	inject('cleanTokenName', cleanTokenName)
	inject('alphaNullUndefinedFirst', alphaNullUndefinedFirst)
	inject('getUserLabel', getUserLabel)
	inject('formatURL', formatURL)
	inject('isStorybook', isStorybook)
	inject('setupEmbedURL', setupEmbedURL)
	inject('getStorybookRootURL', getStorybookRootURL)
	inject('getStorybookTypeOfURL', getStorybookTypeOfURL)
	inject('storybookComponentLink', storybookComponentLink)
}
