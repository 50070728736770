
export default {
	computed: {
		entity() {
			return this.$store.getters['entity/currentEntity']
		}
	},
	methods: {
		isActive(type) {
			if (Array.isArray(type)) {
				return type.includes(this.entity?.type)
			}

			return this.entity?.type === type
		}
	}
}
