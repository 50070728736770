const initialState = {
	info: {}
}

export const state = () => initialState

export const mutations = {
	initialize(state, data) {
		state.info = data
	}
}

export const actions = {
	async fetch() {
		const subscriberInfo = await this.$axios.$get(
			`/api/billing/subscriber-info`
		)
		this.commit('subscription/initialize', subscriberInfo)
	}
}

export const getters = {
	getInfo: (state) => {
		return state.info || {}
	},
	hasReachedPageLimit: (state) => {
		return state.info.usage?.pages >= state.info.limits?.pages
	},
	hasReachedSourceFileLimit: (state) => {
		return state.info.usage?.sourceFiles >= state.info.limits?.sourceFiles
	},
	hasReachedEditorLimit: (state) => {
		return state.info.usage?.editors >= state.info.limits?.editors
	},
	hasReachedViewerLimit: (state) => {
		return state.info.usage?.viewers >= state.info.limits?.viewers
	}
}
