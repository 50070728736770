
export default {
	props: {
		square: { type: Boolean, default: false },
		height: { type: Number, default: 32 },
		width: { type: Number, default: 32 },
		user: {
			type: Object,
			default() {
				return {
					email: 'support@luroapp.com',
					image: ''
				}
			}
		}
	},
	data() {
		return {
			colors: [
				'#E95A26',
				'#B73053',
				'#37B88E',
				'#E44426',
				'#F77E26',
				'#FDC22A',
				'#1A8C9E'
			]
		}
	}
}
