
export default {
	props: {
		size: {
			type: String,
			default: 'sm'
		},
		align: {
			type: String,
			default: null
		},
		justify: {
			type: String,
			default: null
		},
		wrap: {
			type: String,
			default: null
		},
		tag: {
			type: String,
			default: 'div'
		},
		container: {
			type: Boolean,
			default: false
		}
	}
}
