export const Accordion = () => import('../../src/components/Accordions/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const EntityAccordion = () => import('../../src/components/Accordions/EntityAccordion.vue' /* webpackChunkName: "components/entity-accordion" */).then(c => wrapFunctional(c.default || c))
export const Activity = () => import('../../src/components/Activity/Activity.vue' /* webpackChunkName: "components/activity" */).then(c => wrapFunctional(c.default || c))
export const ActivityDetails = () => import('../../src/components/Activity/ActivityDetails.vue' /* webpackChunkName: "components/activity-details" */).then(c => wrapFunctional(c.default || c))
export const ActivityFeed = () => import('../../src/components/Activity/ActivityFeed.vue' /* webpackChunkName: "components/activity-feed" */).then(c => wrapFunctional(c.default || c))
export const ActivityPhrase = () => import('../../src/components/Activity/ActivityPhrase.vue' /* webpackChunkName: "components/activity-phrase" */).then(c => wrapFunctional(c.default || c))
export const Alert = () => import('../../src/components/Alerts/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const AlertCenter = () => import('../../src/components/Alerts/AlertCenter.vue' /* webpackChunkName: "components/alert-center" */).then(c => wrapFunctional(c.default || c))
export const BillingAlert = () => import('../../src/components/Alerts/BillingAlert.vue' /* webpackChunkName: "components/billing-alert" */).then(c => wrapFunctional(c.default || c))
export const KBAlert = () => import('../../src/components/Alerts/KBAlert.vue' /* webpackChunkName: "components/k-b-alert" */).then(c => wrapFunctional(c.default || c))
export const PromoAlert = () => import('../../src/components/Alerts/PromoAlert.vue' /* webpackChunkName: "components/promo-alert" */).then(c => wrapFunctional(c.default || c))
export const Upsell = () => import('../../src/components/Alerts/Upsell.vue' /* webpackChunkName: "components/upsell" */).then(c => wrapFunctional(c.default || c))
export const WelcomeBack = () => import('../../src/components/Alerts/WelcomeBack.vue' /* webpackChunkName: "components/welcome-back" */).then(c => wrapFunctional(c.default || c))
export const AuditSettingsBar = () => import('../../src/components/Bars/AuditSettingsBar.vue' /* webpackChunkName: "components/audit-settings-bar" */).then(c => wrapFunctional(c.default || c))
export const ProjectRelatedCountsBar = () => import('../../src/components/Bars/ProjectRelatedCountsBar.vue' /* webpackChunkName: "components/project-related-counts-bar" */).then(c => wrapFunctional(c.default || c))
export const CreditCard = () => import('../../src/components/Billing/CreditCard.vue' /* webpackChunkName: "components/credit-card" */).then(c => wrapFunctional(c.default || c))
export const PlanLevels = () => import('../../src/components/Billing/PlanLevels.vue' /* webpackChunkName: "components/plan-levels" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionPlan = () => import('../../src/components/Billing/SubscriptionPlan.vue' /* webpackChunkName: "components/subscription-plan" */).then(c => wrapFunctional(c.default || c))
export const SearchBlock = () => import('../../src/components/Blocks/SearchBlock.vue' /* webpackChunkName: "components/search-block" */).then(c => wrapFunctional(c.default || c))
export const StatBlock = () => import('../../src/components/Blocks/StatBlock.vue' /* webpackChunkName: "components/stat-block" */).then(c => wrapFunctional(c.default || c))
export const StickyBottom = () => import('../../src/components/Blocks/StickyBottom.vue' /* webpackChunkName: "components/sticky-bottom" */).then(c => wrapFunctional(c.default || c))
export const ImprovementBrick = () => import('../../src/components/Bricks/ImprovementBrick.vue' /* webpackChunkName: "components/improvement-brick" */).then(c => wrapFunctional(c.default || c))
export const StatusBrick = () => import('../../src/components/Bricks/StatusBrick.vue' /* webpackChunkName: "components/status-brick" */).then(c => wrapFunctional(c.default || c))
export const CalloutButton = () => import('../../src/components/Buttons/CalloutButton.vue' /* webpackChunkName: "components/callout-button" */).then(c => wrapFunctional(c.default || c))
export const DefaultPopper = () => import('../../src/components/Buttons/DefaultPopper.vue' /* webpackChunkName: "components/default-popper" */).then(c => wrapFunctional(c.default || c))
export const DropdownButton = () => import('../../src/components/Buttons/DropdownButton.vue' /* webpackChunkName: "components/dropdown-button" */).then(c => wrapFunctional(c.default || c))
export const DropdownButtonItem = () => import('../../src/components/Buttons/DropdownButtonItem.vue' /* webpackChunkName: "components/dropdown-button-item" */).then(c => wrapFunctional(c.default || c))
export const FeedbackButton = () => import('../../src/components/Buttons/FeedbackButton.vue' /* webpackChunkName: "components/feedback-button" */).then(c => wrapFunctional(c.default || c))
export const MeatballMenuButton = () => import('../../src/components/Buttons/MeatballMenuButton.vue' /* webpackChunkName: "components/meatball-menu-button" */).then(c => wrapFunctional(c.default || c))
export const SubvariantButton = () => import('../../src/components/Buttons/SubvariantButton.vue' /* webpackChunkName: "components/subvariant-button" */).then(c => wrapFunctional(c.default || c))
export const TeamPicker = () => import('../../src/components/Buttons/TeamPicker.vue' /* webpackChunkName: "components/team-picker" */).then(c => wrapFunctional(c.default || c))
export const Zendesk = () => import('../../src/components/Buttons/Zendesk.vue' /* webpackChunkName: "components/zendesk" */).then(c => wrapFunctional(c.default || c))
export const ImageLinkCard = () => import('../../src/components/Cards/ImageLinkCard.vue' /* webpackChunkName: "components/image-link-card" */).then(c => wrapFunctional(c.default || c))
export const ProjectCard = () => import('../../src/components/Cards/ProjectCard.vue' /* webpackChunkName: "components/project-card" */).then(c => wrapFunctional(c.default || c))
export const StatCard = () => import('../../src/components/Cards/StatCard.vue' /* webpackChunkName: "components/stat-card" */).then(c => wrapFunctional(c.default || c))
export const AddComponentDialog = () => import('../../src/components/Dialog/AddComponentDialog.vue' /* webpackChunkName: "components/add-component-dialog" */).then(c => wrapFunctional(c.default || c))
export const AddStatusDialog = () => import('../../src/components/Dialog/AddStatusDialog.vue' /* webpackChunkName: "components/add-status-dialog" */).then(c => wrapFunctional(c.default || c))
export const ConfirmDialog = () => import('../../src/components/Dialog/ConfirmDialog.vue' /* webpackChunkName: "components/confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const DefaultModal = () => import('../../src/components/Dialog/DefaultModal.vue' /* webpackChunkName: "components/default-modal" */).then(c => wrapFunctional(c.default || c))
export const LimitDialog = () => import('../../src/components/Dialog/LimitDialog.vue' /* webpackChunkName: "components/limit-dialog" */).then(c => wrapFunctional(c.default || c))
export const LinkDialog = () => import('../../src/components/Dialog/LinkDialog.vue' /* webpackChunkName: "components/link-dialog" */).then(c => wrapFunctional(c.default || c))
export const MissingProfileInfoDialog = () => import('../../src/components/Dialog/MissingProfileInfoDialog.vue' /* webpackChunkName: "components/missing-profile-info-dialog" */).then(c => wrapFunctional(c.default || c))
export const RelationDialog = () => import('../../src/components/Dialog/RelationDialog.vue' /* webpackChunkName: "components/relation-dialog" */).then(c => wrapFunctional(c.default || c))
export const UploadDialog = () => import('../../src/components/Dialog/UploadDialog.vue' /* webpackChunkName: "components/upload-dialog" */).then(c => wrapFunctional(c.default || c))
export const UploadImageDialog = () => import('../../src/components/Dialog/UploadImageDialog.vue' /* webpackChunkName: "components/upload-image-dialog" */).then(c => wrapFunctional(c.default || c))
export const VideoDialog = () => import('../../src/components/Dialog/VideoDialog.vue' /* webpackChunkName: "components/video-dialog" */).then(c => wrapFunctional(c.default || c))
export const EmptyState = () => import('../../src/components/EmptyStates/EmptyState.vue' /* webpackChunkName: "components/empty-state" */).then(c => wrapFunctional(c.default || c))
export const EmptyStateProjectDetail = () => import('../../src/components/EmptyStates/EmptyStateProjectDetail.vue' /* webpackChunkName: "components/empty-state-project-detail" */).then(c => wrapFunctional(c.default || c))
export const EmptyViewerState = () => import('../../src/components/EmptyStates/EmptyViewerState.vue' /* webpackChunkName: "components/empty-viewer-state" */).then(c => wrapFunctional(c.default || c))
export const Onboarding = () => import('../../src/components/EmptyStates/Onboarding.vue' /* webpackChunkName: "components/onboarding" */).then(c => wrapFunctional(c.default || c))
export const BubbleEditor = () => import('../../src/components/Entities/BubbleEditor.vue' /* webpackChunkName: "components/bubble-editor" */).then(c => wrapFunctional(c.default || c))
export const CodeBlockComponent = () => import('../../src/components/Entities/CodeBlockComponent.vue' /* webpackChunkName: "components/code-block-component" */).then(c => wrapFunctional(c.default || c))
export const CodeHighlight = () => import('../../src/components/Entities/CodeHighlight.vue' /* webpackChunkName: "components/code-highlight" */).then(c => wrapFunctional(c.default || c))
export const ComponentProgressMeter = () => import('../../src/components/Entities/ComponentProgressMeter.vue' /* webpackChunkName: "components/component-progress-meter" */).then(c => wrapFunctional(c.default || c))
export const ComponentTrackingChart = () => import('../../src/components/Entities/ComponentTrackingChart.vue' /* webpackChunkName: "components/component-tracking-chart" */).then(c => wrapFunctional(c.default || c))
export const ComponentTrackingTable = () => import('../../src/components/Entities/ComponentTrackingTable.vue' /* webpackChunkName: "components/component-tracking-table" */).then(c => wrapFunctional(c.default || c))
export const Editor = () => import('../../src/components/Entities/Editor.vue' /* webpackChunkName: "components/editor" */).then(c => wrapFunctional(c.default || c))
export const EmbedPreview = () => import('../../src/components/Entities/EmbedPreview.vue' /* webpackChunkName: "components/embed-preview" */).then(c => wrapFunctional(c.default || c))
export const EmbedViewer = () => import('../../src/components/Entities/EmbedViewer.vue' /* webpackChunkName: "components/embed-viewer" */).then(c => wrapFunctional(c.default || c))
export const EntityFooter = () => import('../../src/components/Entities/EntityFooter.vue' /* webpackChunkName: "components/entity-footer" */).then(c => wrapFunctional(c.default || c))
export const EntityHeaderTemplate = () => import('../../src/components/Entities/EntityHeaderTemplate.vue' /* webpackChunkName: "components/entity-header-template" */).then(c => wrapFunctional(c.default || c))
export const EntityTemplate = () => import('../../src/components/Entities/EntityTemplate.vue' /* webpackChunkName: "components/entity-template" */).then(c => wrapFunctional(c.default || c))
export const LighthouseHistoryChart = () => import('../../src/components/Entities/LighthouseHistoryChart.vue' /* webpackChunkName: "components/lighthouse-history-chart" */).then(c => wrapFunctional(c.default || c))
export const RelatedInsights = () => import('../../src/components/Entities/RelatedInsights.vue' /* webpackChunkName: "components/related-insights" */).then(c => wrapFunctional(c.default || c))
export const TagList = () => import('../../src/components/Entities/TagList.vue' /* webpackChunkName: "components/tag-list" */).then(c => wrapFunctional(c.default || c))
export const EntityHeader = () => import('../../src/components/Headers/EntityHeader.vue' /* webpackChunkName: "components/entity-header" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../src/components/Headers/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PublicEntityHeader = () => import('../../src/components/Headers/PublicEntityHeader.vue' /* webpackChunkName: "components/public-entity-header" */).then(c => wrapFunctional(c.default || c))
export const CircularLoading = () => import('../../src/components/Icons/CircularLoading.vue' /* webpackChunkName: "components/circular-loading" */).then(c => wrapFunctional(c.default || c))
export const Deselect = () => import('../../src/components/Icons/Deselect.vue' /* webpackChunkName: "components/deselect" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../src/components/Icons/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const LogoEye = () => import('../../src/components/Icons/LogoEye.vue' /* webpackChunkName: "components/logo-eye" */).then(c => wrapFunctional(c.default || c))
export const PerfChart = () => import('../../src/components/Insights/PerfChart.vue' /* webpackChunkName: "components/perf-chart" */).then(c => wrapFunctional(c.default || c))
export const PerfDot = () => import('../../src/components/Insights/PerfDot.vue' /* webpackChunkName: "components/perf-dot" */).then(c => wrapFunctional(c.default || c))
export const Progress = () => import('../../src/components/Insights/Progress.vue' /* webpackChunkName: "components/progress" */).then(c => wrapFunctional(c.default || c))
export const FigmaComponentAnalytics = () => import('../../src/components/Integrations/FigmaComponentAnalytics.vue' /* webpackChunkName: "components/figma-component-analytics" */).then(c => wrapFunctional(c.default || c))
export const SourceFiles = () => import('../../src/components/Integrations/SourceFiles.vue' /* webpackChunkName: "components/source-files" */).then(c => wrapFunctional(c.default || c))
export const JobTickets = () => import('../../src/components/Jobs/JobTickets.vue' /* webpackChunkName: "components/job-tickets" */).then(c => wrapFunctional(c.default || c))
export const PageLayout = () => import('../../src/components/Layouts/PageLayout.vue' /* webpackChunkName: "components/page-layout" */).then(c => wrapFunctional(c.default || c))
export const ProjectLayout = () => import('../../src/components/Layouts/ProjectLayout.vue' /* webpackChunkName: "components/project-layout" */).then(c => wrapFunctional(c.default || c))
export const AttachmentLink = () => import('../../src/components/Links/AttachmentLink.vue' /* webpackChunkName: "components/attachment-link" */).then(c => wrapFunctional(c.default || c))
export const SimpleLink = () => import('../../src/components/Links/SimpleLink.vue' /* webpackChunkName: "components/simple-link" */).then(c => wrapFunctional(c.default || c))
export const DefaultTabs = () => import('../../src/components/Navigation/DefaultTabs.vue' /* webpackChunkName: "components/default-tabs" */).then(c => wrapFunctional(c.default || c))
export const MainNav = () => import('../../src/components/Navigation/MainNav.vue' /* webpackChunkName: "components/main-nav" */).then(c => wrapFunctional(c.default || c))
export const PublicShareNav = () => import('../../src/components/Navigation/PublicShareNav.vue' /* webpackChunkName: "components/public-share-nav" */).then(c => wrapFunctional(c.default || c))
export const Subnav = () => import('../../src/components/Navigation/Subnav.vue' /* webpackChunkName: "components/subnav" */).then(c => wrapFunctional(c.default || c))
export const SearchAutocomplete = () => import('../../src/components/Search/SearchAutocomplete.vue' /* webpackChunkName: "components/search-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const ChartSelect = () => import('../../src/components/Select/ChartSelect.vue' /* webpackChunkName: "components/chart-select" */).then(c => wrapFunctional(c.default || c))
export const ColorSelect = () => import('../../src/components/Select/ColorSelect.vue' /* webpackChunkName: "components/color-select" */).then(c => wrapFunctional(c.default || c))
export const OwnerSelect = () => import('../../src/components/Select/OwnerSelect.vue' /* webpackChunkName: "components/owner-select" */).then(c => wrapFunctional(c.default || c))
export const SectionSelect = () => import('../../src/components/Select/SectionSelect.vue' /* webpackChunkName: "components/section-select" */).then(c => wrapFunctional(c.default || c))
export const StatusSelect = () => import('../../src/components/Select/StatusSelect.vue' /* webpackChunkName: "components/status-select" */).then(c => wrapFunctional(c.default || c))
export const StorybookWizard = () => import('../../src/components/Storybook/StorybookWizard.vue' /* webpackChunkName: "components/storybook-wizard" */).then(c => wrapFunctional(c.default || c))
export const ActionTable = () => import('../../src/components/Tables/ActionTable.vue' /* webpackChunkName: "components/action-table" */).then(c => wrapFunctional(c.default || c))
export const ActionTableCell = () => import('../../src/components/Tables/ActionTableCell.vue' /* webpackChunkName: "components/action-table-cell" */).then(c => wrapFunctional(c.default || c))
export const ActionTableEditableCell = () => import('../../src/components/Tables/ActionTableEditableCell.vue' /* webpackChunkName: "components/action-table-editable-cell" */).then(c => wrapFunctional(c.default || c))
export const DetailsTable = () => import('../../src/components/Tables/DetailsTable.vue' /* webpackChunkName: "components/details-table" */).then(c => wrapFunctional(c.default || c))
export const LighthouseItemDetailTable = () => import('../../src/components/Tables/LighthouseItemDetailTable.vue' /* webpackChunkName: "components/lighthouse-item-detail-table" */).then(c => wrapFunctional(c.default || c))
export const CodeDocumentationTemplate = () => import('../../src/components/Templates/CodeDocumentationTemplate.vue' /* webpackChunkName: "components/code-documentation-template" */).then(c => wrapFunctional(c.default || c))
export const ComponentTemplate = () => import('../../src/components/Templates/ComponentTemplate.vue' /* webpackChunkName: "components/component-template" */).then(c => wrapFunctional(c.default || c))
export const EntityInsightTemplate = () => import('../../src/components/Templates/EntityInsightTemplate.vue' /* webpackChunkName: "components/entity-insight-template" */).then(c => wrapFunctional(c.default || c))
export const EntitySystemTemplate = () => import('../../src/components/Templates/EntitySystemTemplate.vue' /* webpackChunkName: "components/entity-system-template" */).then(c => wrapFunctional(c.default || c))
export const SectionDocumentationTemplate = () => import('../../src/components/Templates/SectionDocumentationTemplate.vue' /* webpackChunkName: "components/section-documentation-template" */).then(c => wrapFunctional(c.default || c))
export const SectionFeaturedDocumentationTemplate = () => import('../../src/components/Templates/SectionFeaturedDocumentationTemplate.vue' /* webpackChunkName: "components/section-featured-documentation-template" */).then(c => wrapFunctional(c.default || c))
export const StyleTemplate = () => import('../../src/components/Templates/StyleTemplate.vue' /* webpackChunkName: "components/style-template" */).then(c => wrapFunctional(c.default || c))
export const TokenColorCard = () => import('../../src/components/Tokens/TokenColorCard.vue' /* webpackChunkName: "components/token-color-card" */).then(c => wrapFunctional(c.default || c))
export const TokenEffectCard = () => import('../../src/components/Tokens/TokenEffectCard.vue' /* webpackChunkName: "components/token-effect-card" */).then(c => wrapFunctional(c.default || c))
export const TokenFontCard = () => import('../../src/components/Tokens/TokenFontCard.vue' /* webpackChunkName: "components/token-font-card" */).then(c => wrapFunctional(c.default || c))
export const AvatarMarble = () => import('../../src/components/User/AvatarMarble.vue' /* webpackChunkName: "components/avatar-marble" */).then(c => wrapFunctional(c.default || c))
export const AvatarUpload = () => import('../../src/components/User/AvatarUpload.vue' /* webpackChunkName: "components/avatar-upload" */).then(c => wrapFunctional(c.default || c))
export const UserIcon = () => import('../../src/components/User/UserIcon.vue' /* webpackChunkName: "components/user-icon" */).then(c => wrapFunctional(c.default || c))
export const Debug = () => import('../../src/components/Utilities/Debug.vue' /* webpackChunkName: "components/debug" */).then(c => wrapFunctional(c.default || c))
export const HStack = () => import('../../src/components/Utilities/HStack.vue' /* webpackChunkName: "components/h-stack" */).then(c => wrapFunctional(c.default || c))
export const VStack = () => import('../../src/components/Utilities/VStack.vue' /* webpackChunkName: "components/v-stack" */).then(c => wrapFunctional(c.default || c))
export const AddFigmaAnalyticsWizard = () => import('../../src/components/Wizard/AddFigmaAnalyticsWizard.vue' /* webpackChunkName: "components/add-figma-analytics-wizard" */).then(c => wrapFunctional(c.default || c))
export const AddPagesWizard = () => import('../../src/components/Wizard/AddPagesWizard.vue' /* webpackChunkName: "components/add-pages-wizard" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsWizard = () => import('../../src/components/Wizard/AnalyticsWizard.vue' /* webpackChunkName: "components/analytics-wizard" */).then(c => wrapFunctional(c.default || c))
export const CrawlerForm = () => import('../../src/components/Wizard/CrawlerForm.vue' /* webpackChunkName: "components/crawler-form" */).then(c => wrapFunctional(c.default || c))
export const ImportPagesWizard = () => import('../../src/components/Wizard/ImportPagesWizard.vue' /* webpackChunkName: "components/import-pages-wizard" */).then(c => wrapFunctional(c.default || c))
export const LighthouseWizard = () => import('../../src/components/Wizard/LighthouseWizard.vue' /* webpackChunkName: "components/lighthouse-wizard" */).then(c => wrapFunctional(c.default || c))
export const OnboardingWizard = () => import('../../src/components/Wizard/OnboardingWizard.vue' /* webpackChunkName: "components/onboarding-wizard" */).then(c => wrapFunctional(c.default || c))
export const PageCrawlerWizard = () => import('../../src/components/Wizard/PageCrawlerWizard.vue' /* webpackChunkName: "components/page-crawler-wizard" */).then(c => wrapFunctional(c.default || c))
export const PublishWizard = () => import('../../src/components/Wizard/PublishWizard.vue' /* webpackChunkName: "components/publish-wizard" */).then(c => wrapFunctional(c.default || c))
export const SelectPageSource = () => import('../../src/components/Wizard/SelectPageSource.vue' /* webpackChunkName: "components/select-page-source" */).then(c => wrapFunctional(c.default || c))
export const StickyHeaderFooter = () => import('../../src/components/Wizard/StickyHeaderFooter.vue' /* webpackChunkName: "components/sticky-header-footer" */).then(c => wrapFunctional(c.default || c))
export const WizardActivator = () => import('../../src/components/Wizard/WizardActivator.vue' /* webpackChunkName: "components/wizard-activator" */).then(c => wrapFunctional(c.default || c))
export const WizardAlert = () => import('../../src/components/Wizard/WizardAlert.vue' /* webpackChunkName: "components/wizard-alert" */).then(c => wrapFunctional(c.default || c))
export const WizardFlow = () => import('../../src/components/Wizard/WizardFlow.vue' /* webpackChunkName: "components/wizard-flow" */).then(c => wrapFunctional(c.default || c))
export const WizardForm = () => import('../../src/components/Wizard/WizardForm.vue' /* webpackChunkName: "components/wizard-form" */).then(c => wrapFunctional(c.default || c))
export const WizardLoading = () => import('../../src/components/Wizard/WizardLoading.vue' /* webpackChunkName: "components/wizard-loading" */).then(c => wrapFunctional(c.default || c))
export const WizardSuccess = () => import('../../src/components/Wizard/WizardSuccess.vue' /* webpackChunkName: "components/wizard-success" */).then(c => wrapFunctional(c.default || c))
export const FigmaCheck = () => import('../../src/components/figma/FigmaCheck.vue' /* webpackChunkName: "components/figma-check" */).then(c => wrapFunctional(c.default || c))
export const FigmaWizard = () => import('../../src/components/figma/FigmaWizard.vue' /* webpackChunkName: "components/figma-wizard" */).then(c => wrapFunctional(c.default || c))
export const SelectSourceFile = () => import('../../src/components/figma/SelectSourceFile.vue' /* webpackChunkName: "components/select-source-file" */).then(c => wrapFunctional(c.default || c))
export const ComponentSelectorsForm = () => import('../../src/components/Entities/Forms/ComponentSelectorsForm.vue' /* webpackChunkName: "components/component-selectors-form" */).then(c => wrapFunctional(c.default || c))
export const EntityForm = () => import('../../src/components/Entities/Forms/EntityForm.vue' /* webpackChunkName: "components/entity-form" */).then(c => wrapFunctional(c.default || c))
export const EntityTabs = () => import('../../src/components/Entities/Forms/EntityTabs.vue' /* webpackChunkName: "components/entity-tabs" */).then(c => wrapFunctional(c.default || c))
export const InsightsForm = () => import('../../src/components/Entities/Forms/InsightsForm.vue' /* webpackChunkName: "components/insights-form" */).then(c => wrapFunctional(c.default || c))
export const SubvariantForm = () => import('../../src/components/Entities/Forms/SubvariantForm.vue' /* webpackChunkName: "components/subvariant-form" */).then(c => wrapFunctional(c.default || c))
export const VariantForm = () => import('../../src/components/Entities/Forms/VariantForm.vue' /* webpackChunkName: "components/variant-form" */).then(c => wrapFunctional(c.default || c))
export const PublicEntityAccordion = () => import('../../src/components/Entities/Public/PublicEntityAccordion.vue' /* webpackChunkName: "components/public-entity-accordion" */).then(c => wrapFunctional(c.default || c))
export const PublicEntityFooter = () => import('../../src/components/Entities/Public/PublicEntityFooter.vue' /* webpackChunkName: "components/public-entity-footer" */).then(c => wrapFunctional(c.default || c))
export const PublicEntityHeaderTemplate = () => import('../../src/components/Entities/Public/PublicEntityHeaderTemplate.vue' /* webpackChunkName: "components/public-entity-header-template" */).then(c => wrapFunctional(c.default || c))
export const PublicEntityTabs = () => import('../../src/components/Entities/Public/PublicEntityTabs.vue' /* webpackChunkName: "components/public-entity-tabs" */).then(c => wrapFunctional(c.default || c))
export const PublicEntityTemplate = () => import('../../src/components/Entities/Public/PublicEntityTemplate.vue' /* webpackChunkName: "components/public-entity-template" */).then(c => wrapFunctional(c.default || c))
export const PublicUrlForm = () => import('../../src/components/Entities/Public/PublicUrlForm.vue' /* webpackChunkName: "components/public-url-form" */).then(c => wrapFunctional(c.default || c))
export const PublicActionTable = () => import('../../src/components/Tables/Public/PublicActionTable.vue' /* webpackChunkName: "components/public-action-table" */).then(c => wrapFunctional(c.default || c))
export const PublicActionTableCell = () => import('../../src/components/Tables/Public/PublicActionTableCell.vue' /* webpackChunkName: "components/public-action-table-cell" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
