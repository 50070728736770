import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _075db85f = () => interopDefault(import('../src/pages/accessibility/index.vue' /* webpackChunkName: "pages/accessibility/index" */))
const _2d8d8e8a = () => interopDefault(import('../src/pages/account.vue' /* webpackChunkName: "pages/account" */))
const _2250a414 = () => interopDefault(import('../src/pages/account/notifications.vue' /* webpackChunkName: "pages/account/notifications" */))
const _00288a9f = () => interopDefault(import('../src/pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _32bb4f55 = () => interopDefault(import('../src/pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _0394efb2 = () => interopDefault(import('../src/pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _38eb50d2 = () => interopDefault(import('../src/pages/analytics/index.vue' /* webpackChunkName: "pages/analytics/index" */))
const _f53ade0a = () => interopDefault(import('../src/pages/components/index.vue' /* webpackChunkName: "pages/components/index" */))
const _3fb602cb = () => interopDefault(import('../src/pages/documentation/index.vue' /* webpackChunkName: "pages/documentation/index" */))
const _159a7334 = () => interopDefault(import('../src/pages/entities/index.vue' /* webpackChunkName: "pages/entities/index" */))
const _525f7c34 = () => interopDefault(import('../src/pages/latest-updates.vue' /* webpackChunkName: "pages/latest-updates" */))
const _598916ba = () => interopDefault(import('../src/pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _26c63d76 = () => interopDefault(import('../src/pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _3086a155 = () => interopDefault(import('../src/pages/pages/index.vue' /* webpackChunkName: "pages/pages/index" */))
const _71358b7e = () => interopDefault(import('../src/pages/performance/index.vue' /* webpackChunkName: "pages/performance/index" */))
const _388c725c = () => interopDefault(import('../src/pages/projects.vue' /* webpackChunkName: "pages/projects" */))
const _41d58b73 = () => interopDefault(import('../src/pages/prototypes.vue' /* webpackChunkName: "pages/prototypes" */))
const _60234c96 = () => interopDefault(import('../src/pages/report.vue' /* webpackChunkName: "pages/report" */))
const _6d51d84e = () => interopDefault(import('../src/pages/required-profile-info.vue' /* webpackChunkName: "pages/required-profile-info" */))
const _007da2fd = () => interopDefault(import('../src/pages/research.vue' /* webpackChunkName: "pages/research" */))
const _2ebd9d20 = () => interopDefault(import('../src/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _c7a1e904 = () => interopDefault(import('../src/pages/signup-onboarding.vue' /* webpackChunkName: "pages/signup-onboarding" */))
const _1259ae2d = () => interopDefault(import('../src/pages/sourceFiles/index.vue' /* webpackChunkName: "pages/sourceFiles/index" */))
const _37f38cc7 = () => interopDefault(import('../src/pages/styles/index.vue' /* webpackChunkName: "pages/styles/index" */))
const _6bb5a7bf = () => interopDefault(import('../src/pages/team.vue' /* webpackChunkName: "pages/team" */))
const _633f5724 = () => interopDefault(import('../src/pages/team/billing/index.vue' /* webpackChunkName: "pages/team/billing/index" */))
const _4f4d5baf = () => interopDefault(import('../src/pages/team/credentials/index.vue' /* webpackChunkName: "pages/team/credentials/index" */))
const _64d3f49f = () => interopDefault(import('../src/pages/team/integrations.vue' /* webpackChunkName: "pages/team/integrations" */))
const _71651752 = () => interopDefault(import('../src/pages/team/people/index.vue' /* webpackChunkName: "pages/team/people/index" */))
const _2517884a = () => interopDefault(import('../src/pages/team/public-site.vue' /* webpackChunkName: "pages/team/public-site" */))
const _306bbda3 = () => interopDefault(import('../src/pages/team/settings.vue' /* webpackChunkName: "pages/team/settings" */))
const _c2d5681c = () => interopDefault(import('../src/pages/team/status.vue' /* webpackChunkName: "pages/team/status" */))
const _1bf64cce = () => interopDefault(import('../src/pages/team/billing/modify.vue' /* webpackChunkName: "pages/team/billing/modify" */))
const _5ae4fa1f = () => interopDefault(import('../src/pages/team/billing/success.vue' /* webpackChunkName: "pages/team/billing/success" */))
const _caa0add4 = () => interopDefault(import('../src/pages/team/billing/update-payment.vue' /* webpackChunkName: "pages/team/billing/update-payment" */))
const _71d0b93e = () => interopDefault(import('../src/pages/team/credentials/add.vue' /* webpackChunkName: "pages/team/credentials/add" */))
const _120dd9ba = () => interopDefault(import('../src/pages/team/people/edit.vue' /* webpackChunkName: "pages/team/people/edit" */))
const _6aa23ce8 = () => interopDefault(import('../src/pages/team/credentials/edit/_id.vue' /* webpackChunkName: "pages/team/credentials/edit/_id" */))
const _58d6738c = () => interopDefault(import('../src/pages/team/people/_id.vue' /* webpackChunkName: "pages/team/people/_id" */))
const _a21f91c4 = () => interopDefault(import('../src/pages/testing.vue' /* webpackChunkName: "pages/testing" */))
const _c7edeaa8 = () => interopDefault(import('../src/pages/tools/index.vue' /* webpackChunkName: "pages/tools/index" */))
const _299811ac = () => interopDefault(import('../src/pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _42634f26 = () => interopDefault(import('../src/pages/accessibility/settings.vue' /* webpackChunkName: "pages/accessibility/settings" */))
const _2f17c4ee = () => interopDefault(import('../src/pages/analytics/settings.vue' /* webpackChunkName: "pages/analytics/settings" */))
const _8e1300f8 = () => interopDefault(import('../src/pages/callbacks/auth0Login.vue' /* webpackChunkName: "pages/callbacks/auth0Login" */))
const _c37e3122 = () => interopDefault(import('../src/pages/callbacks/figma-oauth.vue' /* webpackChunkName: "pages/callbacks/figma-oauth" */))
const _08108c25 = () => interopDefault(import('../src/pages/callbacks/password-change-success.vue' /* webpackChunkName: "pages/callbacks/password-change-success" */))
const _c81a221e = () => interopDefault(import('../src/pages/components/bulk-editor.vue' /* webpackChunkName: "pages/components/bulk-editor" */))
const _0720ff34 = () => interopDefault(import('../src/pages/entities/new.vue' /* webpackChunkName: "pages/entities/new" */))
const _58bbd3cb = () => interopDefault(import('../src/pages/pages/bulk-editor.vue' /* webpackChunkName: "pages/pages/bulk-editor" */))
const _1e270852 = () => interopDefault(import('../src/pages/pages/crawler.vue' /* webpackChunkName: "pages/pages/crawler" */))
const _35122a32 = () => interopDefault(import('../src/pages/pages/import.vue' /* webpackChunkName: "pages/pages/import" */))
const _12213a70 = () => interopDefault(import('../src/pages/pages/settings.vue' /* webpackChunkName: "pages/pages/settings" */))
const _27f22bf8 = () => interopDefault(import('../src/pages/performance/settings.vue' /* webpackChunkName: "pages/performance/settings" */))
const _9ff06c82 = () => interopDefault(import('../src/pages/testers/healthcheck.vue' /* webpackChunkName: "pages/testers/healthcheck" */))
const _236a7bb2 = () => interopDefault(import('../src/pages/tools/categories.vue' /* webpackChunkName: "pages/tools/categories" */))
const _f50c2924 = () => interopDefault(import('../src/pages/tools/crawler.vue' /* webpackChunkName: "pages/tools/crawler" */))
const _77a80b82 = () => interopDefault(import('../src/pages/tools/dummy.vue' /* webpackChunkName: "pages/tools/dummy" */))
const _0a3e9f58 = () => interopDefault(import('../src/pages/tools/icon-finder.vue' /* webpackChunkName: "pages/tools/icon-finder" */))
const _de51b784 = () => interopDefault(import('../src/pages/tools/search.vue' /* webpackChunkName: "pages/tools/search" */))
const _05e97c74 = () => interopDefault(import('../src/pages/tools/services.vue' /* webpackChunkName: "pages/tools/services" */))
const _4b09547d = () => interopDefault(import('../src/pages/tools/sticky-tester.vue' /* webpackChunkName: "pages/tools/sticky-tester" */))
const _7b7009a0 = () => interopDefault(import('../src/pages/tools/tokens.vue' /* webpackChunkName: "pages/tools/tokens" */))
const _1775488a = () => interopDefault(import('../src/pages/tools/wizard-tester.vue' /* webpackChunkName: "pages/tools/wizard-tester" */))
const _4293308c = () => interopDefault(import('../src/pages/components/settings/figma-analytics.vue' /* webpackChunkName: "pages/components/settings/figma-analytics" */))
const _67f6c2ec = () => interopDefault(import('../src/pages/accessibility/details/_slug.vue' /* webpackChunkName: "pages/accessibility/details/_slug" */))
const _3b2dfbc5 = () => interopDefault(import('../src/pages/entities/category/_id.vue' /* webpackChunkName: "pages/entities/category/_id" */))
const _1535c328 = () => interopDefault(import('../src/pages/performance/details/_slug.vue' /* webpackChunkName: "pages/performance/details/_slug" */))
const _7644b666 = () => interopDefault(import('../src/pages/components/_id/index.vue' /* webpackChunkName: "pages/components/_id/index" */))
const _6ed63e51 = () => interopDefault(import('../src/pages/entities/_id/index.vue' /* webpackChunkName: "pages/entities/_id/index" */))
const _6ca33219 = () => interopDefault(import('../src/pages/invite/_id/index.vue' /* webpackChunkName: "pages/invite/_id/index" */))
const _223a9af0 = () => interopDefault(import('../src/pages/share/_shareId/index.vue' /* webpackChunkName: "pages/share/_shareId/index" */))
const _4b25ea98 = () => interopDefault(import('../src/pages/sourceFiles/_id/index.vue' /* webpackChunkName: "pages/sourceFiles/_id/index" */))
const _3d1aec32 = () => interopDefault(import('../src/pages/styles/_id/index.vue' /* webpackChunkName: "pages/styles/_id/index" */))
const _d2d05da4 = () => interopDefault(import('../src/pages/invite/_id/view.vue' /* webpackChunkName: "pages/invite/_id/view" */))
const _269dc32a = () => interopDefault(import('../src/pages/share/_shareId/components/index.vue' /* webpackChunkName: "pages/share/_shareId/components/index" */))
const _78025237 = () => interopDefault(import('../src/pages/share/_shareId/styles/index.vue' /* webpackChunkName: "pages/share/_shareId/styles/index" */))
const _5a1f0b26 = () => interopDefault(import('../src/pages/components/_id/tracking/_variantId.vue' /* webpackChunkName: "pages/components/_id/tracking/_variantId" */))
const _4faf0bd6 = () => interopDefault(import('../src/pages/share/_shareId/components/_id/index.vue' /* webpackChunkName: "pages/share/_shareId/components/_id/index" */))
const _b72d0cbc = () => interopDefault(import('../src/pages/share/_shareId/styles/_id/index.vue' /* webpackChunkName: "pages/share/_shareId/styles/_id/index" */))
const _3011bb40 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accessibility",
    component: _075db85f,
    name: "accessibility"
  }, {
    path: "/account",
    component: _2d8d8e8a,
    name: "account",
    children: [{
      path: "notifications",
      component: _2250a414,
      name: "account-notifications"
    }, {
      path: "password",
      component: _00288a9f,
      name: "account-password"
    }, {
      path: "profile",
      component: _32bb4f55,
      name: "account-profile"
    }, {
      path: "settings",
      component: _0394efb2,
      name: "account-settings"
    }]
  }, {
    path: "/analytics",
    component: _38eb50d2,
    name: "analytics"
  }, {
    path: "/components",
    component: _f53ade0a,
    name: "components"
  }, {
    path: "/documentation",
    component: _3fb602cb,
    name: "documentation"
  }, {
    path: "/entities",
    component: _159a7334,
    name: "entities"
  }, {
    path: "/latest-updates",
    component: _525f7c34,
    name: "latest-updates"
  }, {
    path: "/login",
    component: _598916ba,
    name: "login"
  }, {
    path: "/notifications",
    component: _26c63d76,
    name: "notifications"
  }, {
    path: "/pages",
    component: _3086a155,
    name: "pages"
  }, {
    path: "/performance",
    component: _71358b7e,
    name: "performance"
  }, {
    path: "/projects",
    component: _388c725c,
    name: "projects"
  }, {
    path: "/prototypes",
    component: _41d58b73,
    name: "prototypes"
  }, {
    path: "/report",
    component: _60234c96,
    name: "report"
  }, {
    path: "/required-profile-info",
    component: _6d51d84e,
    name: "required-profile-info"
  }, {
    path: "/research",
    component: _007da2fd,
    name: "research"
  }, {
    path: "/reset",
    component: _2ebd9d20,
    name: "reset"
  }, {
    path: "/signup-onboarding",
    component: _c7a1e904,
    name: "signup-onboarding"
  }, {
    path: "/sourceFiles",
    component: _1259ae2d,
    name: "sourceFiles"
  }, {
    path: "/styles",
    component: _37f38cc7,
    name: "styles"
  }, {
    path: "/team",
    component: _6bb5a7bf,
    name: "team",
    children: [{
      path: "billing",
      component: _633f5724,
      name: "team-billing"
    }, {
      path: "credentials",
      component: _4f4d5baf,
      name: "team-credentials"
    }, {
      path: "integrations",
      component: _64d3f49f,
      name: "team-integrations"
    }, {
      path: "people",
      component: _71651752,
      name: "team-people"
    }, {
      path: "public-site",
      component: _2517884a,
      name: "team-public-site"
    }, {
      path: "settings",
      component: _306bbda3,
      name: "team-settings"
    }, {
      path: "status",
      component: _c2d5681c,
      name: "team-status"
    }, {
      path: "billing/modify",
      component: _1bf64cce,
      name: "team-billing-modify"
    }, {
      path: "billing/success",
      component: _5ae4fa1f,
      name: "team-billing-success"
    }, {
      path: "billing/update-payment",
      component: _caa0add4,
      name: "team-billing-update-payment"
    }, {
      path: "credentials/add",
      component: _71d0b93e,
      name: "team-credentials-add"
    }, {
      path: "people/edit",
      component: _120dd9ba,
      name: "team-people-edit"
    }, {
      path: "credentials/edit/:id?",
      component: _6aa23ce8,
      name: "team-credentials-edit-id"
    }, {
      path: "people/:id",
      component: _58d6738c,
      name: "team-people-id"
    }]
  }, {
    path: "/testing",
    component: _a21f91c4,
    name: "testing"
  }, {
    path: "/tools",
    component: _c7edeaa8,
    name: "tools"
  }, {
    path: "/verify-email",
    component: _299811ac,
    name: "verify-email"
  }, {
    path: "/accessibility/settings",
    component: _42634f26,
    name: "accessibility-settings"
  }, {
    path: "/analytics/settings",
    component: _2f17c4ee,
    name: "analytics-settings"
  }, {
    path: "/callbacks/auth0Login",
    component: _8e1300f8,
    name: "callbacks-auth0Login"
  }, {
    path: "/callbacks/figma-oauth",
    component: _c37e3122,
    name: "callbacks-figma-oauth"
  }, {
    path: "/callbacks/password-change-success",
    component: _08108c25,
    name: "callbacks-password-change-success"
  }, {
    path: "/components/bulk-editor",
    component: _c81a221e,
    name: "components-bulk-editor"
  }, {
    path: "/entities/new",
    component: _0720ff34,
    name: "entities-new"
  }, {
    path: "/pages/bulk-editor",
    component: _58bbd3cb,
    name: "pages-bulk-editor"
  }, {
    path: "/pages/crawler",
    component: _1e270852,
    name: "pages-crawler"
  }, {
    path: "/pages/import",
    component: _35122a32,
    name: "pages-import"
  }, {
    path: "/pages/settings",
    component: _12213a70,
    name: "pages-settings"
  }, {
    path: "/performance/settings",
    component: _27f22bf8,
    name: "performance-settings"
  }, {
    path: "/testers/healthcheck",
    component: _9ff06c82,
    name: "testers-healthcheck"
  }, {
    path: "/tools/categories",
    component: _236a7bb2,
    name: "tools-categories"
  }, {
    path: "/tools/crawler",
    component: _f50c2924,
    name: "tools-crawler"
  }, {
    path: "/tools/dummy",
    component: _77a80b82,
    name: "tools-dummy"
  }, {
    path: "/tools/icon-finder",
    component: _0a3e9f58,
    name: "tools-icon-finder"
  }, {
    path: "/tools/search",
    component: _de51b784,
    name: "tools-search"
  }, {
    path: "/tools/services",
    component: _05e97c74,
    name: "tools-services"
  }, {
    path: "/tools/sticky-tester",
    component: _4b09547d,
    name: "tools-sticky-tester"
  }, {
    path: "/tools/tokens",
    component: _7b7009a0,
    name: "tools-tokens"
  }, {
    path: "/tools/wizard-tester",
    component: _1775488a,
    name: "tools-wizard-tester"
  }, {
    path: "/components/settings/figma-analytics",
    component: _4293308c,
    name: "components-settings-figma-analytics"
  }, {
    path: "/accessibility/details/:slug?",
    component: _67f6c2ec,
    name: "accessibility-details-slug"
  }, {
    path: "/entities/category/:id?",
    component: _3b2dfbc5,
    name: "entities-category-id"
  }, {
    path: "/performance/details/:slug?",
    component: _1535c328,
    name: "performance-details-slug"
  }, {
    path: "/components/:id",
    component: _7644b666,
    name: "components-id"
  }, {
    path: "/entities/:id",
    component: _6ed63e51,
    name: "entities-id"
  }, {
    path: "/invite/:id",
    component: _6ca33219,
    name: "invite-id"
  }, {
    path: "/share/:shareId",
    component: _223a9af0,
    name: "share-shareId"
  }, {
    path: "/sourceFiles/:id",
    component: _4b25ea98,
    name: "sourceFiles-id"
  }, {
    path: "/styles/:id",
    component: _3d1aec32,
    name: "styles-id"
  }, {
    path: "/invite/:id?/view",
    component: _d2d05da4,
    name: "invite-id-view"
  }, {
    path: "/share/:shareId?/components",
    component: _269dc32a,
    name: "share-shareId-components"
  }, {
    path: "/share/:shareId?/styles",
    component: _78025237,
    name: "share-shareId-styles"
  }, {
    path: "/components/:id/tracking/:variantId?",
    component: _5a1f0b26,
    name: "components-id-tracking-variantId"
  }, {
    path: "/share/:shareId?/components/:id",
    component: _4faf0bd6,
    name: "share-shareId-components-id"
  }, {
    path: "/share/:shareId?/styles/:id",
    component: _b72d0cbc,
    name: "share-shareId-styles-id"
  }, {
    path: "/",
    component: _3011bb40,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
