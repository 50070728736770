export const errorsThatShouldOnlyBeWarnings = ['FigmaAuthError']

export class FigmaAuthError extends Error {
	constructor(message) {
		const customMessage =
			message ||
			'Unable to authenticate with Figma, please connect to Figma again.'
		super(customMessage)
		Error.captureStackTrace(this, this.constructor)

		this.name = this.constructor.name
		this.status = 401
		this.statusCode = 401
	}
}

export class FigmaMissingPermissionsError extends Error {
	constructor(message) {
		const customMessage =
			message ||
			'Figma user does not have the required permissions to access this data.'
		super(customMessage)
		Error.captureStackTrace(this, this.constructor)

		this.name = this.constructor.name
		this.status = 400
		this.statusCode = 400
	}
}
