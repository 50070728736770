
export default {
	props: {
		height: {
			type: Number,
			default: 10
		},
		width: {
			type: Number,
			default: 10
		}
	}
}
