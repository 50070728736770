
export default {
	data() {
		return {
			alert: null
		}
	},
	mounted() {
		this.$nuxt.$on('alert', (payload) => {
			this.alert = payload
		})
		this.$nuxt.$on('dismissAlert', () => {
			this.alert = null
		})
	},
	beforeDestroy() {
		this.$nuxt.$off('alert')
		this.$nuxt.$off('dismissAlert')
	}
}
