const middleware = {}

middleware['admin'] = require('../src/middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['editor'] = require('../src/middleware/editor.js')
middleware['editor'] = middleware['editor'].default || middleware['editor']

middleware['team'] = require('../src/middleware/team.js')
middleware['team'] = middleware['team'].default || middleware['team']

export default middleware
