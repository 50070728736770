import { render, staticRenderFns } from "./shortform.vue?vue&type=template&id=10b4ff54&"
var script = {}
import style0 from "./shortform.vue?vue&type=style&index=0&id=10b4ff54&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AlertCenter: require('/opt/render/project/src/apps/nuxt/src/components/Alerts/AlertCenter.vue').default,LogoEye: require('/opt/render/project/src/apps/nuxt/src/components/Icons/LogoEye.vue').default})
