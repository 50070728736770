
export default {
	layout: 'shortform',
	props: {
		error: {
			type: Object,
			default: () => {}
		}
	},
	mounted() {
		if (!this.$auth.loggedIn) {
			this.$router.push('/login')
		}
	}
}
