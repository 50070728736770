export const state = () => ({
	files: []
})

export const mutations = {
	initialize(state, files) {
		state.files = files
	},
	add(state, file) {
		state.files.push(file)
	},
	update(state, file) {
		const newlist = state.files.filter((f) => f.id !== file.id)
		state.files = [...newlist, file]
	},
	remove(state, file) {
		state.files = state.files.filter((f) => f.id !== file.id)
	}
}

export const actions = {
	async fetch() {
		const files = await this.$axios.$get('/api/source-files')
		this.commit('sourceFiles/initialize', files)
	}
}

export const getters = {
	getSourceFiles: (state) => {
		return state.files
	},
	getSourceFilesBySource: (state) => (source) => {
		if (!source) {
			return state.files
		}

		return state.files.filter((f) => f.source === source)
	}
}
