const initialState = {
	stats: []
}

export const state = () => initialState

export const mutations = {
	set(state, { stats }) {
		state.stats = stats
	},
	clear(state) {
		state.stats = []
	}
}

export const actions = {
	async fetch({ state }) {
		const data = await this.$axios.$get(
			`/api/tracking/components?include=adhoc`
		)
		this.commit('componentTracking/set', {
			stats: data
		})
	}
}

export const getters = {}
