import { render, staticRenderFns } from "./publicShare.vue?vue&type=template&id=37bdf5f0&scoped=true&"
import script from "./publicShare.vue?vue&type=script&lang=js&"
export * from "./publicShare.vue?vue&type=script&lang=js&"
import style0 from "./publicShare.vue?vue&type=style&index=0&id=37bdf5f0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37bdf5f0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserIcon: require('/opt/render/project/src/apps/nuxt/src/components/User/UserIcon.vue').default,PublicShareNav: require('/opt/render/project/src/apps/nuxt/src/components/Navigation/PublicShareNav.vue').default,StickyHeaderFooter: require('/opt/render/project/src/apps/nuxt/src/components/Wizard/StickyHeaderFooter.vue').default,AlertCenter: require('/opt/render/project/src/apps/nuxt/src/components/Alerts/AlertCenter.vue').default})
