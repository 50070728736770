
import { nanoid } from 'nanoid'
import { Popper } from 'popper-vue'
import 'popper-vue/dist/popper-vue.css'

export default {
	name: 'DefaultPopper',
	components: {
		Popper
	},
	props: {
		popperContainerClass: {
			type: String,
			default: ''
		},
		placement: {
			type: String,
			default: 'bottom'
		}
	},
	data() {
		return {
			open: false,
			id: nanoid()
		}
	},
	methods: {
		click(e) {
			this.openPopper()
		},
		openPopper() {
			this.open = !this.open
			this.$emit('onOpen')
		},
		hidePopper() {
			this.open = false
			this.$emit('onClose')
		}
	}
}
