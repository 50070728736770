/*
 ** Workaround for layout with overflow-y: scroll on <main>
 */
export default function (to, from, savedPosition) {
	if (savedPosition) {
		return savedPosition
	} else {
		const main = document.querySelector('main')
		if (main) {
			main.scrollTo(0, 0)
		}
		return { x: 0, y: 0 }
	}
}
