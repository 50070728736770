export function currentUserRole(user) {
	if (!user) {
		return 'NONE'
	}

	const userRole = user.teamRoles?.find(
		(role) => role.teamId === this.$auth.$storage.getCookie('currentTeam')
	)

	return userRole?.role || user.role
}

export function currentTeamId() {
	return this.$auth.$storage.getCookie('currentTeam')
}

export function isViewer(role) {
	return !isEditorOrAbove(role)
}

export function isEditorOrAbove(role) {
	return ['EDITOR', 'ADMIN', 'OWNER'].includes(role)
}

export function isAdminOrAbove(role) {
	return ['ADMIN', 'OWNER'].includes(role)
}

export function isOwner(role) {
	return role === 'OWNER'
}

export function isSuperAdmin(user) {
	// TODO: Works for now
	return user?.email?.endsWith('@luroapp.com')
}

export default ({ app }, inject) => {
	inject('currentUserRole', currentUserRole)
	inject('isViewer', isViewer)
	inject('isEditorOrAbove', isEditorOrAbove)
	inject('isAdminOrAbove', isAdminOrAbove)
	inject('isOwner', isOwner)
	inject('isSuperAdmin', isSuperAdmin)
}
