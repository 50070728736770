import { AnalyticsBrowser } from '@segment/analytics-next'

const proxyURL = `telemetry.luroapp.com`

const segment = AnalyticsBrowser.load(
	{
		writeKey: process.env.SEGMENT_ENABLED ? process.env.SEGMENT_WRITE_KEY : '',
		cdnURL: `https://${proxyURL}`
	},
	{
		obfuscate: true,
		integrations: {
			'Segment.io': {
				// https://api.segment.io/v1/t -> https://MY-CUSTOM-API-PROXY.com/t
				apiHost: `${proxyURL}/v1`,
				protocol: 'https'
			}
		}
	}
)

export default ({ app }, inject) => {
	app.router.afterEach(function (to, from) {
		segment.page('', to.name || '', {
			path: to.fullPath
		})
	})
	inject('segment', segment)
}
