import { render, staticRenderFns } from "./TeamPicker.vue?vue&type=template&id=4bb870a0&"
import script from "./TeamPicker.vue?vue&type=script&lang=js&"
export * from "./TeamPicker.vue?vue&type=script&lang=js&"
import style0 from "./TeamPicker.vue?vue&type=style&index=0&id=4bb870a0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserIcon: require('/opt/render/project/src/apps/nuxt/src/components/User/UserIcon.vue').default,DefaultModal: require('/opt/render/project/src/apps/nuxt/src/components/Dialog/DefaultModal.vue').default,MainNav: require('/opt/render/project/src/apps/nuxt/src/components/Navigation/MainNav.vue').default})
