import groupBy from 'lodash.groupby'

const sortByOrder = (a, b) =>
	a.properties?.order?.componentView - b.properties?.order?.componentView

const initialState = {
	currentEntity: {},
	componentsBySourceFileTitle: {},
	stylesBySourceFileTitle: {},
	publicEntities: [],
	team: {}
}

export const state = () => initialState

export const mutations = {
	clear(state) {
		state.currentEntity = {}
		state.componentsBySourceFileTitle = {}
		state.stylesBySourceFileTitle = {}
	},
	setCurrentEntity(state, entity) {
		state.currentEntity = entity
	},
	setPublicEntities(
		state,
		{ componentsBySourceFileTitle, stylesBySourceFileTitle, publicEntities }
	) {
		state.componentsBySourceFileTitle = componentsBySourceFileTitle
		state.stylesBySourceFileTitle = stylesBySourceFileTitle
		state.publicEntities = publicEntities
	},
	setTeam(state, team) {
		state.team = team
	}
}

export const actions = {
	clear() {
		this.commit('public/clear')
	},
	setCurrentEntity({ state }, entity) {
		this.commit('public/setCurrentEntity', entity)
	},
	async initialize({ state, $router }, teamId) {
		try {
			const publicEntities = await this.$axios.$get(
				`/api/public-share/${teamId}`
			)
			const team = await this.$axios.$get(`/api/public-share/team/${teamId}`)

			this.commit('public/setTeam', team)

			const components = publicEntities.filter(
				(entity) => entity.type === 'COMPONENT'
			)
			// filter out child components/variants
			const styles = publicEntities.filter(
				(entity) => entity.type === 'STYLE' && entity.parentId === null
			)

			const componentsGroupedBySourceFileTitle = groupBy(
				components,
				'sourceFile.title'
			)

			const orderedComponentssGroupedBySourceFileTitle = Object.keys(
				componentsGroupedBySourceFileTitle
			)
				.sort(this.$alphaNullUndefinedFirst())
				.reduce((obj, key) => {
					obj[key] = componentsGroupedBySourceFileTitle[key]
					return obj
				}, {})

			const stylesGroupedBySourceFileTitle = groupBy(styles, 'sourceFile.title')

			const orderedStylesGroupedBySourceFileTitle = Object.keys(
				stylesGroupedBySourceFileTitle
			)
				.sort(this.$alphaNullUndefinedFirst())
				.reduce((obj, key) => {
					obj[key] = stylesGroupedBySourceFileTitle[key]
					return obj
				}, {})

			this.commit('public/setPublicEntities', {
				publicEntities,
				componentsBySourceFileTitle: orderedComponentssGroupedBySourceFileTitle,
				stylesBySourceFileTitle: orderedStylesGroupedBySourceFileTitle
			})
		} catch (err) {
			if (err.response?.status === 404) {
				return this.$router.push('/404')
			}
		}
	}
}

export const getters = {
	currentEntity: (state) => {
		const entity = JSON.parse(JSON.stringify(state.currentEntity))

		if (entity.variants) {
			for (const variant of entity.variants) {
				if (variant.variants) {
					for (const subvariant of variant.variants) {
						subvariant.properties = {
							...subvariant.properties,
							design: {
								...subvariant.properties?.design,
								backgroundColor:
									subvariant.properties?.design?.backgroundColor || '#ffffff'
							}
						}
					}

					variant.variants = variant.variants.sort(sortByOrder)
				}

				variant.properties = {
					...variant.properties,
					design: {
						...variant.properties?.design,
						display: variant.properties?.design?.display || 'two-across'
					}
				}
			}

			entity.variants = entity.variants.sort(sortByOrder)
		}

		return entity
	},
	publicEntities: (state) => {
		return state.publicEntities
	},
	componentsBySourceFileTitle: (state) => {
		return state.componentsBySourceFileTitle
	},
	stylesBySourceFileTitle: (state) => {
		return state.stylesBySourceFileTitle
	},
	team: (state) => {
		return state.team
	},
	publicEntitiesGroupedByType: (state) => {
		const entities = JSON.parse(JSON.stringify(state.publicEntities))

		const grouped = {}

		for (const entity of entities) {
			if (!grouped[entity.type]) {
				grouped[entity.type] = []
			}

			grouped[entity.type].push(entity)
		}

		return grouped
	}
}
