import logger from 'logger'

const addInstance = (obj) => {
	if (typeof obj !== 'object') {
		obj = {
			data: obj
		}
	}
	obj.instance = obj.instance || 'nuxt-app'
	return obj
}

export default (context, inject) => {
	inject('logger', (msg, obj = {}) =>
		logger[obj.level || 'log'](msg, addInstance(obj), context)
	)
}
