export const formatDate = (arg) => {
	try {
		return new Intl.DateTimeFormat('en-US', {
			day: '2-digit',
			month: 'short',
			year: 'numeric',
			timeZone: 'UTC'
		}).format(new Date(arg))
	} catch (e) {
		return ''
	}
}

export const formatDateTime = (arg) => {
	const Day = formatDate(arg)
	const Time = formatTime(arg)
	return `${Day} at ${Time}`
}

export const formatTime = (arg) => {
	return new Intl.DateTimeFormat('en-US', {
		timeStyle: 'short'
	}).format(new Date(arg))
}

export const isWithinMinutes = (date, threshold = 5) => {
	if (date === null) {
		date = new Date()
	}
	if (typeof date === 'string') {
		date = new Date(date)
	}
	date.setMinutes(date.getMinutes() + threshold)
	return date > new Date()
}

export default ({ app }, inject) => {
	inject('formatDate', formatDate)
	inject('formatDateTime', formatDateTime)
	inject('formatTime', formatTime)
	inject('isWithinMinutes', isWithinMinutes)
}
