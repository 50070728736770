export const roundToTwo = (num) => {
	if (typeof num !== 'number' || isNaN(num)) {
		throw new TypeError('Expected a number')
	}

	return +(Math.round(num + 'e+2') + 'e-2')
}

export default ({ app }, inject) => {
	inject('roundToTwo', roundToTwo)
}
