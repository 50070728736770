
export default {
	data() {
		return {
			pollTimeoutId: null
		}
	},
	watch: {
		$route(to, from) {
			// Clear entity store if navigating away from an entity page
			if (from.name.includes('-id') && !to.name.includes('-id')) {
				this.$store.dispatch('entity/clear')
			}

			// Set default team if none found at anytime during navigation, some times logic in mounted is not enough
			if (!this.$auth.$storage.getCookie('currentTeam')) {
				const teamId = this.$auth.user.teams[0].id
				this.$auth.$storage.setCookie('currentTeam', teamId, false)
				// force app refresh, nuxt refresh was not handling the cookie change
				location.reload()
			}
		}
	},
	async mounted() {
		// Set default team if none found.
		if (!this.$auth.$storage.getCookie('currentTeam')) {
			const teamId = this.$auth.user.teams[0].id
			this.$auth.$storage.setCookie('currentTeam', teamId, false)
			// force app refresh, nuxt refresh was not handling the cookie change
			location.reload()
		}

		this.$nuxt.$on('logout', this.logout)
		// fetch for source files if list is empty on mount
		if (!this.$store.state.sourceFiles.files.length) {
			this.$store.dispatch('sourceFiles/fetch')
		}

		this.$store.dispatch('jobTickets/fetch')

		if (!this.$store.state.figmaData.teamIds?.length) {
			await this.$store.dispatch('figmaData/getTeams')
			await this.$store.dispatch('figmaData/getAnalytics')
		}

		this.updatePollTimer()

		// Send data to segment
		const currentTeam = this.$auth.user.teams.find(
			(team) => team.id === this.$auth.$storage.getCookie('currentTeam')
		)

		this.$segment.group(currentTeam.id, {
			name: currentTeam.title,
			avatar: currentTeam.image
		})

		this.$segment.identify(this.$auth.user.id, {
			email: this.$auth.user.email,
			title: this.$auth.user.title,
			avatar: this.$auth.user.image
		})
	},
	beforeDestroy() {
		this.$nuxt.$off('logout')
		clearTimeout(this.pollTimeoutId)
	},
	methods: {
		updatePollTimer() {
			this.$store.dispatch('subscription/fetch')
			clearTimeout(this.pollTimeoutId)
			this.pollTimeoutId = setTimeout(this.updatePollTimer, 60000)
		},
		async logout() {
			this.$segment.track(
				'Logged Out',
				{},
				{
					context: {
						groupId: this.$auth?.$storage?.getCookie('currentTeam')
					}
				}
			)
			await this.$auth.logout()
			this.$segment.reset()
		}
	}
}
