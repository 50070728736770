import { render, staticRenderFns } from "./default.vue?vue&type=template&id=bcc7af34&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=bcc7af34&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcc7af34",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TeamPicker: require('/opt/render/project/src/apps/nuxt/src/components/Buttons/TeamPicker.vue').default,MainNav: require('/opt/render/project/src/apps/nuxt/src/components/Navigation/MainNav.vue').default,UserIcon: require('/opt/render/project/src/apps/nuxt/src/components/User/UserIcon.vue').default,VStack: require('/opt/render/project/src/apps/nuxt/src/components/Utilities/VStack.vue').default,DefaultPopper: require('/opt/render/project/src/apps/nuxt/src/components/Buttons/DefaultPopper.vue').default,StickyHeaderFooter: require('/opt/render/project/src/apps/nuxt/src/components/Wizard/StickyHeaderFooter.vue').default,AlertCenter: require('/opt/render/project/src/apps/nuxt/src/components/Alerts/AlertCenter.vue').default,FeedbackButton: require('/opt/render/project/src/apps/nuxt/src/components/Buttons/FeedbackButton.vue').default})
