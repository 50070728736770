const initialState = {
	entities: []
}

export const state = () => initialState

export const mutations = {
	set(state, { entities }) {
		state.entities = entities
	},
	clear(state) {
		state.entities = []
	}
}

export const actions = {
	async fetch({ state }) {
		const entities = await this.$axios.$get(`/api/entities/components-tree`)
		this.commit('componentTree/set', {
			entities
		})
	}
}

export const getters = {}
