export class analyticsHelper {
	static latestData(item) {
		return item.analytics[0]?.data || null
	}

	static previousData(item) {
		return item.analytics[1]?.data || null
	}

	static formatNumber(number, style) {
		if (number === null) {
			return null
		}

		let formattedNumber
		switch (style) {
			case 'percent':
				formattedNumber =
					new Intl.NumberFormat().format(parseFloat(number).toFixed(2)) + '%'
				break
			default:
				formattedNumber = new Intl.NumberFormat().format(number)
		}

		return formattedNumber
	}

	static getLatestKey(item, key) {
		const m1 = this.latestData(item)
		// eslint-disable-next-line no-prototype-builtins
		return m1?.hasOwnProperty(key) ? m1[key] : null
	}

	static getPreviousKey(item, key) {
		const m1 = this.previousData(item)
		// eslint-disable-next-line no-prototype-builtins
		return m1?.hasOwnProperty(key) ? m1[key] : null
	}
}

export default ({ app }, inject) => {
	inject('analytics', analyticsHelper)
}
