import Vue from 'vue'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules.umd'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('email', {
	...email,
	message: 'Please enter a valid email address'
})

extend('required', {
	...required,
	message: 'This field is required'
})

export const isValidURL = (value) => {
	try {
		// eslint-disable-next-line no-unused-vars
		const url = new URL(value)
		return true
	} catch (e) {
		return false
	}
}

extend('url', {
	validate: isValidURL,
	message: 'Please enter a valid URL'
})

export const auth0Validator = (value) => {
	if (
		value.includes('/u/login?state=') ||
		value.includes('/u/login/identifier?state=') ||
		value.includes('?state=')
	) {
		return false
	}
	return true
}

const auth0Rule = {
	message: `Login URLs with state parameters are not allowed. Please use a non-stateful URL.`,
	validate: auth0Validator
}

extend('auth0State', auth0Rule)

export default ({ app }, inject) => {
	inject('isValidURL', isValidURL)
}
