
import { nanoid } from 'nanoid'

export default {
	name: 'DefaultModal',
	props: {
		locked: {
			type: Boolean,
			default: false
		},
		overflowVisible: {
			type: Boolean,
			default: false
		},
		showClose: {
			type: Boolean,
			default: true
		},
		eventScope: {
			type: String,
			default: 'modal'
		},
		className: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			styles: '',
			embiggen: false,
			open: false,
			id: nanoid()
		}
	},
	mounted() {
		this.handleDialog()
		this.styles = this.$el.getAttribute('style')
		this.$nuxt.$on(`${this.eventScope}Embiggen`, () => {
			// only embiggen if open
			this.embiggen = !!this.open
		})
		this.$nuxt.$on(`${this.eventScope}Shrink`, () => {
			this.embiggen = false
		})
		this.$nuxt.$on(`${this.eventScope}ForceOpen`, this.click)
		this.$nuxt.$on(`${this.eventScope}Close`, this.onClose)
	},
	updated() {
		this.handleDialog()
	},
	beforeDestroy() {
		// eslint-disable-next-line nuxt/no-env-in-hooks
		if (process.client) {
			this.previousFocus = undefined
			delete this.previousFocus
		}

		this.$nuxt.$off(`${this.eventScope}Embiggen`)
		this.$nuxt.$off(`${this.eventScope}Shrink`)
		this.$nuxt.$off(`${this.eventScope}ForceOpen`)
		this.$nuxt.$off(`${this.eventScope}Close`)
	},
	methods: {
		async handleDialog() {
			if (process.client) {
				// double nextTick for the portal
				await this.$nextTick() // setup the teleport
				await this.$nextTick() // move the element to the teleport
				if (!window.HTMLDialogElement) {
					const dialogPolyfill = (await import('dialog-polyfill')).default
					dialogPolyfill.registerDialog(this.$refs.dialog)
				}
			}
		},
		click(e) {
			if (process.client) {
				e?.preventDefault()
				this.previousFocus = e?.target
				this.open = true
				if (this.$refs[this.id]) {
					this.$refs[this.id].showModal()
					this.$nuxt.$emit(`${this.eventScope}Open`)
				}
			}
		},
		onClickDialog(e) {
			e.stopPropagation()
			if (this.locked) return
			if (e.target === this.$refs[this.id]) {
				if (
					e.layerX < 0 ||
					e.layerX > e.target.clientWidth ||
					e.layerY < 0 ||
					e.layerY > e.target.clientHeight
				) {
					this.onClose(e)
				}
			}
		},
		onDialogOpen(e) {
			// eslint-disable-next-line no-console
			console.log(e)
		},
		async onClose(event) {
			if (this.$refs[this.id]?.open) {
				await this.$refs[this.id].close()
			}
			this.open = false
			if (this.previousFocus) {
				this.previousFocus.focus()
				this.previousFocus = undefined
				delete this.previousFocus
			}
		}
	}
}
