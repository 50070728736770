
export default {
	computed: {
		entity() {
			return this.$store.getters['public/currentEntity']
		},
		componentsBySourceFileTitle() {
			return this.$store.getters['public/componentsBySourceFileTitle']
		},
		componentsGroupLength() {
			return Object.keys(this.componentsBySourceFileTitle).length
		},
		stylesBySourceFileTitle() {
			return this.$store.getters['public/stylesBySourceFileTitle']
		},
		stylesGroupLength() {
			return Object.keys(this.stylesBySourceFileTitle).length
		}
	},
	methods: {
		isActive(type, id) {
			if (id) {
				return this.$route.params.id === id
			}

			if (Array.isArray(type)) {
				return type.includes(this.entity?.type)
			}

			return this.entity?.type === type
		}
	}
}
