
export default {
	auth: false,
	async asyncData({ $axios, route, redirect }) {
		try {
			const publicSiteSettings = await $axios.$get(
				`/api/public-share/publicSiteSettings/${route.params.shareId}`
			)

			if (!publicSiteSettings || !publicSiteSettings.enabled) {
				redirect('/404')
			}

			return {
				publicSiteSettings,
				publicSiteUrl: `${process.env.SITE_URL}/share/${publicSiteSettings.token}`
			}
		} catch (error) {
			redirect('/404')
		}
	},
	computed: {
		team() {
			return this.$store.getters['public/team']
		}
	},
	mounted() {
		const shareId = this.$route.params.shareId

		this.$store.dispatch('public/initialize', shareId)
	},
	beforeDestroy() {},
	methods: {}
}
