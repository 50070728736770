
export default {
	name: 'FeedbackButton',
	mounted() {
		this.$nuxt.$on('hideFeedbackPopper', this.hidePopper)
		this.$zendesk.$on('close', this.closeWidget)
	},

	beforeDestroy() {
		this.$nuxt.$off('hideFeedbackPopper')
	},
	methods: {
		hidePopper() {
			this.$refs.feedbackPopperButton.style.opacity = 0
			this.$refs.feedbackPopper.hidePopper()
		},
		closeWidget() {
			this.$zendesk.zE('webWidget', 'hide')
			this.$refs.feedbackPopperButton.style.opacity = 1
		},
		openWidget() {
			this.$zendesk.zE('webWidget', 'prefill', {
				name: {
					value: `${this.$getUserLabel(this.$auth.user)}`
				},
				email: {
					value: this.$auth.user.email,
					readOnly: true // optional
				}
			})

			this.$zendesk.zE('webWidget', 'show')
			this.$zendesk.zE('webWidget', 'open')
			this.$refs.feedbackPopperButton.style.opacity = 0
			this.$refs.feedbackPopper.hidePopper()
		}
	}
}
