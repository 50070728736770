export const state = () => ({
	figmaTeams: [],
	analytics: null
})

export const mutations = {
	setFigmaTeams(state, data) {
		state.figmaTeams = data
	},
	async addFigmaTeam(state, figmaTeam) {
		// Set local state
		state.figmaTeams = [...new Set([...state.figmaTeams, figmaTeam])]
		// Save to DB
		const { id, ...teamData } = await this.$axios.$get(`/api/teams`)
		teamData.metadata = {
			...teamData.metadata,
			figmaTeams: state.figmaTeams
		}
		await this.$axios.$patch(`/api/teams/${id}`, {
			metadata: teamData.metadata
		})
	},
	async removeFigmaTeam(state, figmaTeamId) {
		// Set local state
		state.figmaTeams = state.figmaTeams.filter(
			(figmaTeam) => figmaTeam.id !== figmaTeamId
		)
		// Save to DB
		const { id, ...teamData } = await this.$axios.$get(`/api/teams`)
		teamData.metadata = {
			...teamData.metadata,
			figmaTeams: state.figmaTeams
		}
		await this.$axios.$patch(`/api/teams/${id}`, {
			metadata: teamData.metadata
		})
	},
	setAnalytics(state, data) {
		state.analytics = data
	},
	removeAnalytics(state) {
		state.analytics = null
	}
}

export const actions = {
	async getTeams({ commit }) {
		const team = await this.$axios.$get(`/api/teams`)
		commit('setFigmaTeams', team.metadata?.figmaTeams || [])
	},
	// Fetch analytics from Figma
	async runAnalytics({ commit, state }) {
		if (state.figmaTeams?.length) {
			await this.$enqueueJob({
				type: 'FIGMA_ANALYTICS',
				endpoint: '/api/figma/analytics'
			})
		}
	},
	// Get analytics from DB
	async getAnalytics({ commit, state }) {
		const figmaAnalytics = await this.$axios.$get(`/api/figma/analytics`)
		commit('setAnalytics', figmaAnalytics?.data)
	}
}
