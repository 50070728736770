
const ELEMENTS = 3
const SIZE = 80

const getNumber = (name) => {
	const charactersArray = Array.from(name)
	let charactersCodesSum = 0

	charactersArray.forEach((charactersArrayItem) => {
		return (charactersCodesSum += charactersArrayItem.charCodeAt(0))
	})

	return charactersCodesSum
}

export const getDigit = (number, ntn) => {
	return Math.floor((number / Math.pow(10, ntn)) % 10)
}

export const getRandomColor = (number, colors, range) => {
	return colors[number % range]
}

export const getUnit = (number, range, index) => {
	const value = number % range

	if (index && getDigit(number, index) % 2 === 0) {
		return -value
	} else return value
}

function generateColors(name, colors) {
	const numFromName = name ? getNumber(name) : 123456789
	const range = colors && colors.length

	const elementsProperties = Array.from({ length: ELEMENTS }, (_, i) => ({
		color: getRandomColor(numFromName + i, colors, range),
		translateX: getUnit(numFromName * (i + 1), SIZE / 10, 1),
		translateY: getUnit(numFromName * (i + 1), SIZE / 10, 2),
		scale: 1.2 + getUnit(numFromName * (i + 1), SIZE / 20) / 10,
		rotate: getUnit(numFromName * (i + 1), 360, 1)
	}))

	return elementsProperties
}

export default {
	props: {
		name: {
			type: String,
			default: ''
		},
		colors: {
			type: Array,
			default: () => [
				'#FF4F28',
				'#D61361',
				'#310821',
				'#FC1E39',
				'#FFB11E',
				'#12A97C',
				'#198B9F'
			]
		},
		square: {
			type: Boolean,
			default: false
		},
		size: {
			type: Number,
			default: 32
		},
		hollow: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			SIZE
		}
	},
	computed: {
		properties() {
			return generateColors(this.name, this.colors)
		}
	}
}
