const sortByOrder = (a, b) =>
	a.properties?.order?.componentView - b.properties?.order?.componentView

const initialState = {
	editMode: false,
	currentEntity: {}
}

export const state = () => initialState

export const mutations = {
	setEditMode(state, editMode) {
		state.editMode = editMode
	},
	clear(state) {
		state.editMode = false
		state.currentEntity = {}
	},
	setCurrentEntity(state, entity) {
		state.currentEntity = entity
	},
	updateCurrentEntity(state, entity) {
		state.currentEntity = {
			...state.currentEntity,
			...entity
		}
	},
	setCurrentEntityVariant(state, variant) {
		const variants = state.currentEntity.variants.map((v) =>
			v.id === variant.id ? variant : v
		)

		variants.sort(sortByOrder)
		state.currentEntity.variants = variants
	},
	updateCurrentEntityVariant(state, variant) {
		const variants = state.currentEntity.variants.map((v) =>
			v.id === variant.id ? { ...v, ...variant } : v
		)

		variants.sort(sortByOrder)
		state.currentEntity.variants = variants
	},
	updateCurrentEntitySubvariant(state, subvariant) {
		const variants = state.currentEntity.variants.map((variant) => {
			if (variant.id === subvariant.parentId) {
				const subvariants = variant.variants.map((v) =>
					v.id === subvariant.id ? subvariant : v
				)

				variant.variants = subvariants.sort(sortByOrder)
			}
			return variant
		})

		state.currentEntity.variants = variants.sort(sortByOrder)
	},
	updateCurrentEntitySubvariants(state, subvariants) {
		const variants = state.currentEntity.variants.map((variant) => {
			if (variant.id === subvariants[0].parentId) {
				variant.variants = subvariants
			}
			return variant
		})
		state.currentEntity.variants = variants.sort(sortByOrder)
	}
}

export const actions = {
	clear() {
		this.commit('entity/clear')
	},
	toggleEditMode({ state }) {
		this.commit('entity/setEditMode', !state.editMode)
	},
	openEditMode() {
		this.commit('entity/setEditMode', true)
	},
	closeEditMode() {
		this.commit('entity/setEditMode', false)
	},
	setCurrentEntity({ state }, entity) {
		this.commit('entity/setCurrentEntity', entity)
	},
	updateCurrentEntity({ state }, entity) {
		this.commit('entity/updateCurrentEntity', entity)
	},
	setCurrentEntityVariant({ state }, variant) {
		this.commit('entity/setCurrentEntityVariant', variant)
	},
	updateCurrentEntityVariant({ state }, variant) {
		this.commit('entity/updateCurrentEntityVariant', variant)
	},
	updateCurrentEntitySubvariant({ state }, subvariant) {
		this.commit('entity/updateCurrentEntitySubvariant', subvariant)
	},
	async updateVariantsSort({ state }, variants) {
		const cleaned = variants
			.map((variant, index) => ({
				...variant,
				properties: {
					...variant.properties,
					order: { ...variant.properties?.order, componentView: index }
				}
			}))
			.sort(sortByOrder)

		await this.$axios.patch(`/api/entities/properties`, {
			entities: cleaned
		})

		this.commit('entity/updateCurrentEntity', {
			variants: cleaned
		})
	},
	async updateSubvariantsSort({ state }, subvariants) {
		const cleaned = subvariants.map((subvariant, index) => ({
			...subvariant,
			properties: {
				...subvariant.properties,
				order: { ...subvariant.properties?.order, componentView: index }
			}
		}))

		await this.$axios.patch(`/api/entities/properties`, {
			entities: cleaned
		})

		this.commit('entity/updateCurrentEntitySubvariants', cleaned)
	},
	async updateVariantViewLayout({ state }, { type, variant }) {
		const update = {
			...variant,
			properties: {
				...variant.properties,
				design: {
					...variant.properties?.design,
					subvariantView: type
				}
			}
		}

		this.commit('entity/setCurrentEntityVariant', update)

		await this.$axios.patch(`/api/entities/properties`, {
			entities: [update]
		})
	}
}

export const getters = {
	editMode: (state) => {
		return state.editMode
	},
	currentEntity: (state) => {
		const entity = JSON.parse(JSON.stringify(state.currentEntity))

		if (entity.variants) {
			for (const variant of entity.variants) {
				if (variant.variants) {
					for (const subvariant of variant.variants) {
						subvariant.properties = {
							...subvariant.properties,
							design: {
								...subvariant.properties?.design,
								backgroundColor:
									subvariant.properties?.design?.backgroundColor || '#ffffff'
							}
						}
					}

					variant.variants = variant.variants.sort(sortByOrder)
				}

				variant.properties = {
					...variant.properties,
					design: {
						...variant.properties?.design,
						display: variant.properties?.design?.display || 'two-across'
					}
				}
			}

			entity.variants = entity.variants.sort(sortByOrder)
		}

		return entity
	}
}
