import { render, staticRenderFns } from "./UserIcon.vue?vue&type=template&id=67b24254&scoped=true&"
import script from "./UserIcon.vue?vue&type=script&lang=js&"
export * from "./UserIcon.vue?vue&type=script&lang=js&"
import style0 from "./UserIcon.vue?vue&type=style&index=0&id=67b24254&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67b24254",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AvatarMarble: require('/opt/render/project/src/apps/nuxt/src/components/User/AvatarMarble.vue').default})
