const defaultState = {
	activities: {
		latest: [],
		latestTotalCount: 0
	}
}

export const state = () => defaultState

export const mutations = {
	setLatest(state, { activities, totalCount }) {
		state.activities.latest = activities
		state.activities.latestTotalCount = totalCount
	},
	clearLatest(state) {
		state.activities.latest = []
	}
}

export const actions = {
	async fetchLatest({ state }, page = 1) {
		const { data, totalCount } = await this.$axios.$get(
			`/api/activities/latest/${page}`
		)
		this.commit('activity/setLatest', {
			activities: data,
			totalCount
		})
	}
}

export const getters = {
	getLatest: (state) => {
		return state.activities.latest
	},
	getLatestTotalCount: (state) => {
		return state.activities.latestTotalCount
	}
}
