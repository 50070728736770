import Vue from 'vue'

export function prettyBytes(num) {
	// jacked from: https://github.com/sindresorhus/pretty-bytes
	if (typeof num !== 'number' || isNaN(num)) {
		throw new TypeError('Expected a number')
	}

	const neg = num < 0
	const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	if (neg) {
		num = -num
	}

	if (num < 1) {
		return (neg ? '-' : '') + num + ' B'
	}

	const exponent = Math.min(
		Math.floor(Math.log(num) / Math.log(1000)),
		units.length - 1
	)
	num = (num / Math.pow(1000, exponent)).toFixed(2) * 1

	const unit = units[exponent]

	return (neg ? '-' : '') + num + ' ' + unit
}

export function formatCurrency(value) {
	const locale = 'en-US'
	const currency = 'USD'
	const formatter = new Intl.NumberFormat(locale, {
		style: 'currency',
		currency
	})

	const isNumeric = !Number.isNaN(parseFloat(value))
	if (!isNumeric) {
		return value
	}

	return formatter.format(parseFloat(value, 10)).replace('.00', '')
}

Vue.filter('prettyBytes', prettyBytes)
Vue.filter('currency', formatCurrency)
