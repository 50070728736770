
export default {
	data() {
		return {
			newTeam: ''
		}
	},
	computed: {
		sortedTeams() {
			if (this.$auth?.user?.teams) {
				return [...this.$auth.user.teams].sort((a, b) =>
					a.title.localeCompare(b.title)
				)
			} else {
				return []
			}
		},
		currentTeam() {
			return (
				(this.$auth &&
					this.$auth.user &&
					this.$auth.$storage.getCookie('currentTeam') &&
					this.$auth.user.teams.find(
						(team) => team.id === this.$auth.$storage.getCookie('currentTeam')
					)) ||
				{}
			)
		}
	},
	methods: {
		async createTeam() {
			const team = await this.$axios.$post('/api/teams/', {
				title: this.newTeam
			})
			this.setTeam(team.id)
		},
		setTeam(teamId) {
			// Stash previous team ID
			const prevTeamId = this.$auth.$storage.getCookie('currentTeam')
			localStorage.setItem(`last_viewed_${prevTeamId}`, this.$route.path)

			// Set the new team ID
			this.$auth.user.currentTeam = teamId
			this.$auth.$storage.setCookie('currentTeam', teamId, false)

			// Check localstorage for last viewed URL
			if (localStorage.getItem(`last_viewed_${teamId}`)) {
				location = localStorage.getItem(`last_viewed_${teamId}`)
			} else {
				location = '/'
			}
		}
	}
}
