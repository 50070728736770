export const entityRouteName = (entity, model = 'entities', isPublic) => {
	if (!entity) return null
	if (isPublic && entity.type === 'COMPONENT') {
		return 'share-shareId-components-id'
	} else if (isPublic && entity.type === 'STYLE') {
		return 'share-shareId-styles-id'
	} else if (['COMPONENT', 'CODE', 'DESIGN'].includes(entity.type)) {
		return 'components-id'
	} else if (entity.type === 'STYLE') {
		return 'styles-id'
	} else {
		return `${model}-id`
	}
}
export const entityQuery = (entity) => {
	if (!entity) return null
	if (['CODE', 'DESIGN'].includes(entity.type)) {
		return { tab: entity.type.toLowerCase() }
	} else {
		return null
	}
}
export const entityRouteId = (entity, shareId, isPublic) => {
	if (!entity) return null
	if (isPublic) {
		return {
			shareId,
			id: entity.id
		}
	} else if (entity.type === 'COMPONENT') {
		return { id: entity.id }
	} else if (['CODE', 'DESIGN'].includes(entity.type)) {
		return {
			id: entity.parent?.parentId || entity.parentId
		}
	} else if (entity.type === 'STYLE') {
		return {
			id: entity.parent?.parentId || entity.parentId || entity.id
		}
	} else {
		return { id: entity.id }
	}
}

/**
 * Recursive lookup for a compoennt in an array of nested component trees
 * @param {UUID} id
 * @param {Array} array of nested component trees
 * @returns {Object} entity component object
 */
const lookupComponentId = (id, array) => {
	// recursively look for component
	if (!array.length) return null
	return array.find((c) => c.id === id || lookupComponentId(id, c.variants))
}

/**
 * Get a flattened list of nested component titles
 * https://stackoverflow.com/questions/31430359/javascript-deep-find-object-in-tree-then-return-object-and-the-path-to-it-thro
 * @param {UUID} id
 * @param {Object} entity component tree
 * @returns {Array} a flattened list of nested component titles
 */
function _findInTree(id, tree) {
	if (tree.id === id) {
		const path = [tree.title]
		return { result: tree, path }
	} else {
		for (const component of tree.variants) {
			const tmp = _findInTree(id, component)
			if (tmp.path) {
				tmp.path.unshift(tree.title)
				return tmp
			}
		}
		return {}
	}
}

/**
 * Find the parent route id for a given entity in an array of nested component trees
 * @param {Object} entity - entity object
 * @param {Array} componentTree - array of nested component trees
 * @returns {UUID}
 */
export const getEntityRouteIdFromComponentTree = (
	entity,
	componentTree = []
) => {
	if (!entity) return null

	const entityFoundInTree = lookupComponentId(entity.id, componentTree)

	return entityFoundInTree?.id
}

export const getEntityRouteBreadcrumbTextFromComponentTree = (
	entity,
	componentTree = []
) => {
	if (!entity) return null

	const entityFoundInTree = lookupComponentId(entity.id, componentTree)

	return entityFoundInTree
		? _findInTree(entity.id, entityFoundInTree)?.path.join(' > ')
		: null
}

export default ({ app }, inject) => {
	inject('entityRouteName', entityRouteName)
	inject('entityQuery', entityQuery)
	inject('entityRouteId', entityRouteId)
	inject('getEntityRouteIdFromComponentTree', getEntityRouteIdFromComponentTree)
	inject(
		'getEntityRouteBreadcrumbTextFromComponentTree',
		getEntityRouteBreadcrumbTextFromComponentTree
	)
}
