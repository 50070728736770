import get from 'lodash.get'

const populations = [
	'Blind', // 'Without Vision'
	'Low-Vision', // 'Limited Vision'
	'Colorblind', // 'Without Perception of Color'
	'Deaf', // 'Without Hearing'
	'Hard of Hearing', // 'Limited Hearing'
	'Speech Impaired', // 'Without Speech' (Not mapped)
	'Motor Impaired', // 'Limited Manipulation'
	'Limited reach and strength', // 'Limited Reach and Strength' (Not mapped)
	'Cognitively Impaired' // 'Limited Lanugage, Cognitive, and Learning Abilities'
]

// https://www.section508.gov/content/mapping-wcag-to-fpc/
const wcagMap = {
	111: {
		title: '1.1.1 Non-text Content',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/non-text-content.html',
		level: 'A',
		populations: [0, 1, 3, 4, 8]
	},
	121: {
		title: '1.2.1 Audio-only and Video-only (Prerecorded)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/audio-only-and-video-only-prerecorded.html',
		level: 'A',
		populations: [0, 1, 3, 4, 8]
	},
	122: {
		title: '1.2.2 Captions (Prerecorded)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/captions-prerecorded.html',
		level: 'A',
		populations: [3, 4, 8]
	},
	123: {
		title: '1.2.3 Audio Description or Media Alternative (Prerecorded)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/audio-description-or-media-alternative-prerecorded.html',
		level: 'A',
		populations: [0, 1, 8]
	},
	124: {
		title: '1.2.4 Captions (Live)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/captions-live.html',
		level: 'AA',
		populations: [3, 4, 8]
	},
	125: {
		title: '1.2.5 Audio Description (Prerecorded)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/audio-description-prerecorded.html',
		level: 'AA',
		populations: [0, 1, 8]
	},
	/* new -------------------------------------------------------------------- */
	126: {
		title: '1.2.6 Sign Language (Prerecorded)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/sign-language-prerecorded.html',
		level: 'AAA',
		populations: []
	},
	127: {
		title: '1.2.7 Extended Audio Description (Prerecorded)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/extended-audio-description-prerecorded.html',
		level: 'AAA',
		populations: []
	},
	128: {
		title: '1.2.8 Media Alternative (Prerecorded)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/media-alternative-prerecorded.html',
		level: 'AAA',
		populations: []
	},
	129: {
		title: '1.2.9 Audio-only (Live)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/audio-only-live.html',
		level: 'AAA',
		populations: []
	},
	/* end new ---------------------------------------------------------------- */
	131: {
		title: '1.3.1 Info and Relationships',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/info-and-relationships.html',
		level: 'A',
		populations: [0, 1, 8]
	},
	132: {
		title: '1.3.2 Meaningful Sequence',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/meaningful-sequence.html',
		level: 'A',
		populations: [0, 1, 8]
	},
	133: {
		title: '1.3.3 Sensory Characteristics',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/sensory-characteristics.html',
		level: 'A',
		populations: [0, 1, 3, 4, 8]
	},
	/* new -------------------------------------------------------------------- */
	134: {
		title: '1.3.4 Orientation',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/orientation.html',
		level: 'AA',
		populations: []
	},
	135: {
		title: '1.3.5 Identify Input Purpose',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/identify-input-purpose.html',
		level: 'AA',
		populations: []
	},
	136: {
		title: '1.3.6 Identify Purpose',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/identify-purpose.html',
		level: 'AAA',
		populations: []
	},
	/* end new ---------------------------------------------------------------- */
	141: {
		title: '1.4.1 Use of Color',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/use-of-color.html',
		level: 'A',
		populations: [0, 1, 2, 8]
	},
	142: {
		title: '1.4.2 Audio Control',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/audio-control.html',
		level: 'A',
		populations: []
	},
	143: {
		title: '1.4.3 Contrast (Minimum)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html',
		level: 'AA',
		populations: [1, 2]
	},
	144: {
		title: '1.4.4 Resize text',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/resize-text.html',
		level: 'AA',
		populations: [1]
	},
	145: {
		title: '1.4.5 Images of Text',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/images-of-text.html',
		level: 'AA',
		populations: [1, 8]
	},
	/* new -------------------------------------------------------------------- */
	146: {
		title: '1.4.6 Contrast (Enhanced)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/contrast-enhanced.html',
		level: 'AAA',
		populations: []
	},
	147: {
		title: '1.4.7 Low or No Background Audio',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/low-or-no-background-audio.html',
		level: 'AAA',
		populations: []
	},
	148: {
		title: '1.4.8 Visual Presentation',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/visual-presentation.html',
		level: 'AAA',
		populations: []
	},
	149: {
		title: '1.4.9 Images of Text (No Exception)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/images-of-text-no-exception.html',
		level: 'AAA',
		populations: []
	},
	1410: {
		title: '1.4.10 Reflow',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/reflow.html',
		level: 'AA',
		populations: []
	},
	1411: {
		title: '1.4.11 Non-text Contrast',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/non-text-contrast.html',
		level: 'AA',
		populations: []
	},
	1412: {
		title: '1.4.12 Text Spacing',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/text-spacing.html',
		level: 'AA',
		populations: []
	},
	1413: {
		title: '1.4.13 Content on Hover or Focus',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/content-on-hover-or-focus.html',
		level: 'AA',
		populations: []
	},
	/* end new ---------------------------------------------------------------- */
	211: {
		title: '2.1.1 Keyboard',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/keyboard.html',
		level: 'A',
		populations: [0, 1, 6, 8]
	},
	212: {
		title: '2.1.2 No Keyboard Trap',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/no-keyboard-trap.html',
		level: 'A',
		populations: [0, 1, 6]
	},
	/* new -------------------------------------------------------------------- */
	213: {
		title: '2.1.3 Keyboard (No Exception)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/keyboard-no-exception.html',
		level: 'AAA',
		populations: []
	},
	214: {
		title: '2.1.4 Character Key Shortcuts',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/character-key-shortcuts.html',
		level: 'A',
		populations: []
	},
	/* end new ---------------------------------------------------------------- */
	/* experimental ----------------------------------------------------------- */
	'21a': {
		title: 'Experimental: Label Content Name Mismatch ',
		link: 'https://dequeuniversity.com/rules/axe/4.8/label-content-name-mismatch',
		level: '',
		populations: []
	},
	/* end experimental ------------------------------------------------------- */
	221: {
		title: '2.2.1 Timing Adjustable',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/timing-adjustable.html',
		level: 'A',
		populations: [0, 1, 6, 8]
	},
	222: {
		title: '2.2.2 Pause, Stop, Hide',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/pause-stop-hide.html',
		level: 'A',
		populations: [8]
	},
	/* new -------------------------------------------------------------------- */
	223: {
		title: '2.2.3 No Timing',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/no-timing.html',
		level: 'AAA',
		populations: []
	},
	224: {
		title: '2.2.4 Interruptions',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/interruptions.html',
		level: 'AAA',
		populations: []
	},
	225: {
		title: '2.2.5 Re-authenticating',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/re-authenticating.html',
		level: 'AAA',
		populations: []
	},
	226: {
		title: '2.2.6 Timeouts',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/timeouts.html',
		level: 'AAA',
		populations: []
	},
	/* end new ---------------------------------------------------------------- */
	231: {
		title: '2.3.1 Three Flashes or Below Threshold',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/three-flashes-or-below-threshold.html',
		level: 'A',
		populations: []
	},
	/* new -------------------------------------------------------------------- */
	232: {
		title: '2.3.2 Three Flashes',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/three-flashes.html',
		level: 'AAA',
		populations: []
	},
	233: {
		title: '2.3.3 Animation from Interactions',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/animation-from-interactions.html',
		level: 'AAA',
		populations: []
	},
	/* end new ---------------------------------------------------------------- */
	241: {
		title: '2.4.1 Bypass Blocks',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/bypass-blocks.html',
		level: 'A',
		populations: [0, 1, 6, 8]
	},
	242: {
		title: '2.4.2 Page Titled',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/page-titled.html',
		level: 'A',
		populations: [0, 1, 6, 8]
	},
	243: {
		title: '2.4.3 Focus Order',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/focus-order.html',
		level: 'A',
		populations: [0, 1, 6, 8]
	},
	244: {
		title: '2.4.4 Link Purpose (In Context)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/link-purpose-in-context.html',
		level: 'A',
		populations: [0, 1, 6, 8]
	},
	245: {
		title: '2.4.5 Multiple Ways',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/multiple-ways.html',
		level: 'AA',
		populations: [0, 1, 6, 8]
	},
	246: {
		title: '2.4.6 Headings and Labels',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/headings-and-labels.html',
		level: 'AA',
		populations: [0, 1, 6, 8]
	},
	247: {
		title: '2.4.7 Focus Visible',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/focus-visible.html',
		level: 'AA',
		populations: [1, 6, 8]
	},
	/* new -------------------------------------------------------------------- */
	248: {
		title: '2.4.8 Location',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/location.html',
		level: 'AAA',
		populations: []
	},
	249: {
		title: '2.4.9 Link Purpose (Link Only)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/link-purpose-link-only.html',
		level: 'AAA',
		populations: []
	},
	2410: {
		title: '2.4.10 Section Headings',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/section-headings.html',
		level: 'AAA',
		populations: []
	},
	/* WCAG 2.2 ---------------------------------------------------------------- */
	2411: {
		title: '2.4.11 Focus Not Obscured (Minimum)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/focus-appearance-minimum.html',
		level: 'AA',
		populations: []
	},
	2412: {
		title: '2.4.12 Focus Not Obscured (Enhanced)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/focus-appearance-enhanced.html',
		level: 'AAA',
		populations: []
	},
	2413: {
		title: '2.4.13 Focus Appearance',
		link: 'https://www.w3.org/WAI/WCAG22/Understanding/focus-appearance',
		level: 'AA',
		populations: []
	},
	/* end WCAG 2.2 ------------------------------------------------------------- */
	251: {
		title: '2.5.1 Pointer Gestures',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/pointer-gestures.html',
		level: 'A',
		populations: []
	},
	252: {
		title: '2.5.2 Pointer Cancellation',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/pointer-cancellation.html',
		level: 'A',
		populations: []
	},
	253: {
		title: '2.5.3 Label in Name',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/label-in-name.html',
		level: 'A',
		populations: []
	},
	254: {
		title: '2.5.4 Motion Actuation',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/motion-actuation.html',
		level: 'A',
		populations: []
	},
	255: {
		title: '2.5.5 Target Size',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/target-size.html',
		level: 'AAA',
		populations: []
	},
	256: {
		title: '2.5.6 Concurrent Input Mechanisms',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/concurrent-input-mechanisms.html',
		level: 'AAA',
		populations: []
	},
	/* end new ---------------------------------------------------------------- */
	/* WCAG 2.2 ---------------------------------------------------------------- */
	257: {
		title: '2.5.7 Dragging Movements',
		link: 'https://www.w3.org/WAI/WCAG22/Understanding/dragging-movements',
		level: 'AA',
		populations: []
	},
	258: {
		title: '2.5.8 Target Size (Minimum)',
		link: 'https://www.w3.org/WAI/WCAG22/Understanding/target-size-minimum.html',
		level: 'AA',
		populations: []
	},
	/* end WCAG 2.2 ------------------------------------------------------------- */
	311: {
		title: '3.1.1 Language of Page',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/language-of-page.html',
		level: 'A',
		populations: [0, 1, 3, 4, 8]
	},
	312: {
		title: '3.1.2 Language of Parts',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/language-of-parts.html',
		level: 'AA',
		populations: [0, 1, 3, 4, 8]
	},
	/* new -------------------------------------------------------------------- */
	313: {
		title: '3.1.3 Unusual Words',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/unusual-words.html',
		level: 'AAA',
		populations: []
	},
	314: {
		title: '3.1.4 Abbreviations',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/abbreviations.html',
		level: 'AAA',
		populations: []
	},
	315: {
		title: '3.1.5 Reading Level',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/reading-level.html',
		level: 'AAA',
		populations: []
	},
	316: {
		title: '3.1.6 Pronunciation',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/pronunciation.html',
		level: 'AAA',
		populations: []
	},
	/* end new ---------------------------------------------------------------- */
	321: {
		title: '3.2.1 On Focus',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/on-focus.html',
		level: 'A',
		populations: [0, 1, 6, 8]
	},
	322: {
		title: '3.2.2 On Input',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/on-input.html',
		level: 'A',
		populations: [0, 1, 8]
	},
	323: {
		title: '3.2.3 Consistent Navigation',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/consistent-navigation.html',
		level: 'AA',
		populations: [0, 1, 8]
	},
	324: {
		title: '3.2.4 Consistent Identification',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/consistent-identification.html',
		level: 'AA',
		populations: [0, 1, 6, 8]
	},
	/* new -------------------------------------------------------------------- */
	325: {
		title: '3.2.5 Change on Request',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/change-on-request.html',
		level: 'AAA',
		populations: []
	},
	/* end new ---------------------------------------------------------------- */
	/* WCAG 2.2 ---------------------------------------------------------------- */
	326: {
		title: '3.2.6 Consistent Help',
		link: 'https://www.w3.org/WAI/WCAG22/Understanding/consistent-help',
		level: 'A',
		populations: []
	},
	/* end WCAG 2.2 ------------------------------------------------------------- */
	331: {
		title: '3.3.1 Error Identification',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/error-identification.html',
		level: 'A',
		populations: [0, 1, 2, 8]
	},
	332: {
		title: '3.3.2 Labels or Instructions',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/labels-or-instructions.html',
		level: 'A',
		populations: [1, 8]
	},
	333: {
		title: '3.3.3 Error Suggestion',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/error-suggestion.html',
		level: 'AA',
		populations: [0, 1, 6, 8]
	},
	334: {
		title: '3.3.4 Error Prevention (Legal, Financial, Data)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/error-prevention-legal-financial-data.html',
		level: 'AA',
		populations: [0, 1, 2, 3, 4, 6, 8]
	},
	/* new -------------------------------------------------------------------- */
	335: {
		title: '3.3.5 Help',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/help.html',
		level: 'AAA',
		populations: []
	},
	336: {
		title: '3.3.6 Error Prevention (All)',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/error-prevention-all.html',
		level: 'AAA',
		populations: []
	},
	/* end new ---------------------------------------------------------------- */
	/* WCAG 2.2 ---------------------------------------------------------------- */
	337: {
		title: '3.3.7 Reduntant Entry',
		link: 'https://www.w3.org/WAI/WCAG22/Understanding/redundant-entry',
		level: 'A',
		populations: []
	},
	338: {
		title: '3.3.8 Accessible Authentication (Minimum)',
		link: 'https://www.w3.org/WAI/WCAG22/Understanding/accessible-authentication-minimum',
		level: 'AA',
		populations: []
	},
	339: {
		title: '3.3.9 Accessible Authentication (Enhanced)',
		link: 'https://www.w3.org/WAI/WCAG22/Understanding/accessible-authentication-enhanced',
		level: 'AAA',
		populations: []
	},
	/* end WCAG 2.2 ------------------------------------------------------------- */
	411: {
		title: '4.1.1 Parsing',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/parsing.html',
		level: 'A',
		status: 'removed',
		populations: [0, 6, 8]
	},
	412: {
		title: '4.1.2 Name, Role, Value',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/name-role-value.html',
		level: 'A',
		populations: [0, 1, 6, 8]
	},
	/* new -------------------------------------------------------------------- */
	413: {
		title: '4.1.3 Status Messages',
		link: 'https://www.w3.org/WAI/WCAG21/Understanding/status-messages.html',
		level: 'AA',
		populations: []
	}
	/* end new ---------------------------------------------------------------- */
}

/**
 * Get populations from WCAG tags
 * @param {Array} wcagTags
 * @returns {Array} populations
 */
export function getWCAGPopulations(wcagTags) {
	const pops = wcagTags
		.map((tag) =>
			wcagMap[tag.replace('wcag', '')]?.populations?.map(
				(pop) => populations[pop]
			)
		)
		.flat()
		.filter((value, index, self) => self.indexOf(value) === index) // filter unique

	return pops
}

export function getWCAGInfo(tag) {
	return wcagMap[tag.replace('wcag', '')]
}

/**
 *
 * @param {String} auditId - Relevant Audit ID slug
 * @returns {Array} acronyms
 */
export function getWebVitals(auditId) {
	const refs = [
		{
			id: 'first-contentful-paint',
			weight: 10,
			group: 'metrics',
			acronym: 'FCP',
			relevantAudits: [
				'server-response-time',
				'render-blocking-resources',
				'redirects',
				'critical-request-chains',
				'uses-text-compression',
				'uses-rel-preconnect',
				'uses-rel-preload',
				'font-display',
				'unminified-javascript',
				'unminified-css',
				'unused-css-rules'
			]
		},
		{
			id: 'interactive',
			weight: 10,
			group: 'metrics',
			acronym: 'TTI'
		},
		{
			id: 'speed-index',
			weight: 10,
			group: 'metrics',
			acronym: 'SI'
		},
		{
			id: 'total-blocking-time',
			weight: 30,
			group: 'metrics',
			acronym: 'TBT',
			relevantAudits: [
				'long-tasks',
				'third-party-summary',
				'third-party-facades',
				'bootup-time',
				'mainthread-work-breakdown',
				'dom-size',
				'duplicated-javascript',
				'legacy-javascript',
				'viewport'
			]
		},
		{
			id: 'largest-contentful-paint',
			weight: 25,
			group: 'metrics',
			acronym: 'LCP',
			relevantAudits: [
				'server-response-time',
				'render-blocking-resources',
				'redirects',
				'critical-request-chains',
				'uses-text-compression',
				'uses-rel-preconnect',
				'uses-rel-preload',
				'font-display',
				'unminified-javascript',
				'unminified-css',
				'unused-css-rules',
				'largest-contentful-paint-element',
				'preload-lcp-image',
				'unused-javascript',
				'efficient-animated-content',
				'total-byte-weight'
			]
		},
		{
			id: 'cumulative-layout-shift',
			weight: 15,
			group: 'metrics',
			acronym: 'CLS',
			relevantAudits: [
				'layout-shift-elements',
				'non-composited-animations',
				'unsized-images'
			]
		}
	]

	return refs
		.filter((ref) => ref.relevantAudits?.includes(auditId))
		.map((ref) => ref.acronym)
}

export class performanceHelper {
	static get(item, key) {
		return get(item, key)
	}

	static getDateRange(items) {
		const oldestItem = items.find((pg) => pg.performance[1])
		const latestItem = items.find((pg) => pg.performance[0])
		const oldest = oldestItem
			? get(oldestItem, 'performance[1].createdAt')
			: null
		const latest = latestItem
			? get(latestItem, 'performance[0].createdAt')
			: null

		return { oldest, latest }
	}

	static latestAudit(item) {
		if (item.performance && item.performance[0]) return item.performance[0].data
		return {
			performance: { displayValue: null },
			firstContentfulPaint: { displayValue: null },
			largestContentfulPaint: { displayValue: null },
			cumulativeLayoutShift: { displayValue: null }
		}
	}

	static previousAudit(item) {
		if (item.performance && item.performance[1]) return item.performance[1].data
		return null
	}
}

export function formatMs(value) {
	return `${Intl.NumberFormat().format(Math.round(value))} ms`
}

export function formatBytes(value) {
	return `${Intl.NumberFormat().format(
		Math.round((value / 1024) * 100) / 100
	)} KB`
}

export default ({ app }, inject) => {
	inject('perf', performanceHelper)
	inject('getWCAGPopulations', getWCAGPopulations)
	inject('getWCAGInfo', getWCAGInfo)
	inject('getWebVitals', getWebVitals)
	inject('formatMs', formatMs)
	inject('formatBytes', formatBytes)
}
