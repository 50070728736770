// Figma effect types
export const effectType = {
	INNER_SHADOW: 'innerShadow',
	DROP_SHADOW: 'dropShadow',
	LAYER_BLUR: 'layerBlur',
	BACKGROUND_BLUR: 'backgroundBlur'
}

/**
 * Create diamond gradient css/style object from Figma diamond gradient style data
 * https://stackoverflow.com/questions/51536536/is-there-a-way-to-have-a-rectangular-or-diamond-like-gradient-effect-for-a-d
 *
 * @function
 * @name diamondGradient
 * @memberof module:helpers/figma
 *
 * @param {Object} gradientFill Figma gradient fill data
 * @returns {Object} Style/CSS object
 */
const diamondGradient = (gradientFill) => {
	return {
		background: `${['bottom right', 'bottom left', 'top left', 'top right']
			.map((angle) => {
				return `linear-gradient(to ${angle}, ${gradientFill.value.stops
					.map(
						(stop) =>
							`${stop.color.toUpperCase()} ${Math.round(
								(stop.position * 100) / 2
							)}%`
					)
					.join(', ')}) ${angle}`
			})
			.join(', ')}`,
		backgroundSize: '50% 50%',
		backgroundRepeat: 'no-repeat'
	}
}

/**
 * Create diamond gradient css/style string from Figma gradient data
 *
 * @function
 * @name diamondGradientCSS
 * @memberof module:helpers/figma
 *
 * @param {Object} gradientFill Figma gradient fill data
 * @returns {String} CSS string
 */
const diamondGradientCSS = (gradientFill) => {
	return [
		`background: ${['bottom right', 'bottom left', 'top left', 'top right']
			.map((angle) => {
				return `linear-gradient(to ${angle}, ${gradientFill.value.stops
					.map(
						(stop) =>
							`${stop.color.toUpperCase()} ${Math.round(
								(stop.position * 100) / 2
							)}%`
					)
					.join(', ')}) ${angle}`
			})
			.join(', ')};`,
		`background-size: 50% 50%;`,
		`background-repeat: no-repeat;`
	]
}

/**
 * Create gradient css/style object from Figma gradient style data
 *
 * @function
 * @name createGradientCSSFromFigmaToken
 * @memberof module:helpers/figma
 *
 * @param {Object} gradientFill Figma gradient fill data
 * @returns {Object} Style/CSS object
 */
export const createGradientCSSFromFigmaToken = (gradientFill) => {
	if (gradientFill.value.gradientType === 'linear') {
		return {
			background: `linear-gradient(${Math.round(
				gradientFill.value.rotation
			)}deg, ${gradientFill.value.stops
				.map(
					(stop) =>
						`${stop.color.toUpperCase()} ${Math.round(stop.position * 100)}%`
				)
				.join(', ')})`
		}
	} else if (gradientFill.value.gradientType === 'radial') {
		return {
			background: `radial-gradient(${gradientFill.value.stops
				.map(
					(stop) =>
						`${stop.color.toUpperCase()} ${Math.round(stop.position * 100)}%`
				)
				.join(', ')})`
		}
	} else if (gradientFill.value.gradientType === 'angular') {
		return {
			background: `conic-gradient(from ${Math.round(
				gradientFill.value.rotation
			)}deg, ${gradientFill.value.stops
				.map(
					(stop) =>
						`${stop.color.toUpperCase()} ${Math.round(stop.position * 100)}%`
				)
				.join(', ')})`
		}
	} else if (gradientFill.value.gradientType === 'diamond') {
		return diamondGradient(gradientFill)
	}
}

/**
 * Create gradient css/style string from Figma gradient data
 *
 * @function
 * @name gradientCssString
 * @memberof module:helpers/figma
 *
 * @param {Object} gradientFill Figma gradient fill data
 * @returns {String} CSS string
 */
export const gradientCssString = (gradientFill) => {
	if (gradientFill.value.gradientType === 'linear') {
		return `background: linear-gradient(${Math.round(
			gradientFill.value.rotation
		)}deg, ${gradientFill.value.stops
			.map(
				(stop) =>
					`${stop.color.toUpperCase()} ${Math.round(stop.position * 100)}%`
			)
			.join(', ')});`
	} else if (gradientFill.value.gradientType === 'radial') {
		return `background: radial-gradient(${gradientFill.value.stops
			.map(
				(stop) =>
					`${stop.color.toUpperCase()} ${Math.round(stop.position * 100)}%`
			)
			.join(', ')});`
	} else if (gradientFill.value.gradientType === 'angular') {
		return `background: conic-gradient(from ${Math.round(
			gradientFill.value.rotation
		)}deg, ${gradientFill.value.stops
			.map(
				(stop) =>
					`${stop.color.toUpperCase()} ${Math.round(stop.position * 100)}%`
			)
			.join(', ')});`
	} else if (gradientFill.value.gradientType === 'diamond') {
		return diamondGradientCSS(gradientFill)
	}
}

/**
 * Create color css/style string from Figma color data
 *
 * @function
 * @name formatFigmaColor
 * @memberof module:helpers/figma
 *
 * @param {Object} color Figma color fill data
 * @returns {String} CSS string
 */
export const formatFigmaColor = (color) => {
	if (!color.value && !color['0']?.value) {
		return ''
	}

	if (color.type === 'gradient') {
		const css = gradientCssString(color)
		return css
	}

	// hex8 to hex6
	if (
		color.value &&
		color.value.length === 9 &&
		color.value.slice(-2) === 'ff'
	) {
		return color.value.substr(0, 7).toUpperCase()
	} else {
		const numberKeys = Object.keys(color).filter((key) => !isNaN(key))
		return numberKeys.length
			? numberKeys
					.map((key) => color[key].value)
					.join(',')
					.toUpperCase()
			: color.value.toUpperCase()
	}
}

/**
 * Create effect css/style strings from Figma effect style data
 *
 * @function
 * @name effectStyles
 * @memberof module:helpers/figma
 *
 * @param {Object} effectObj Figma effect data
 * @returns {Array} List of Style/CSS strings of effect
 */
export const effectStyles = (effectObj) => {
	const styles = []
	const numberKeys = Object.keys(effectObj).filter((key) => !isNaN(key))

	if (!effectObj.value && !numberKeys.length) return null

	if (numberKeys.length > 1) {
		const multipleEffectStyles = {}
		numberKeys.forEach((key) => {
			const effect = effectObj[key]
			if (effect.value.type === effectType.BACKGROUND_BLUR) {
				styles.push(`backdrop-filter: blur(${effect.value.radius}px)`)
			} else if (effect.value.type === effectType.LAYER_BLUR) {
				styles.push(`filter: blur(${effect.value.radius}px)`)
			} else if (effect.value.type === effectType.DROP_SHADOW) {
				if (multipleEffectStyles['box-shadow']) {
					multipleEffectStyles[
						'box-shadow'
					] += `, ${effect.value.offsetX}px ${effect.value.offsetY}px ${effect.value.radius}px ${effect.value.color}`
				} else {
					multipleEffectStyles[
						'box-shadow'
					] = `box-shadow: ${effect.value.offsetX}px ${effect.value.offsetY}px ${effect.value.radius}px ${effect.value.color}`
				}
			} else if (effect.value.type === effectType.INNER_SHADOW) {
				if (multipleEffectStyles['box-shadow']) {
					multipleEffectStyles[
						'box-shadow'
					] += `, inset ${effect.value.offsetX}px ${effect.value.offsetY}px ${effect.value.radius}px ${effect.value.color}`
				} else {
					multipleEffectStyles[
						'box-shadow'
					] = `box-shadow: inset ${effect.value.offsetX}px ${effect.value.offsetY}px ${effect.value.radius}px ${effect.value.color}`
				}
			}
		})
		if (multipleEffectStyles['box-shadow']) {
			styles.push(multipleEffectStyles['box-shadow'])
		}
	} else if (effectObj.value.type === effectType.BACKGROUND_BLUR) {
		styles.push(`backdrop-filter: blur(${effectObj.value.radius}px)`)
	} else if (effectObj.value.type === effectType.LAYER_BLUR) {
		styles.push(`filter: blur(${effectObj.value.radius}px)`)
	} else if (effectObj.value.type === effectType.DROP_SHADOW) {
		styles.push(
			`box-shadow: ${effectObj.value.offsetX}px ${effectObj.value.offsetY}px ${effectObj.value.radius}px ${effectObj.value.color}`
		)
	} else if (effectObj.value.type === effectType.INNER_SHADOW) {
		styles.push(
			`box-shadow: inset ${effectObj.value.offsetX}px ${effectObj.value.offsetY}px ${effectObj.value.radius}px ${effectObj.value.color}`
		)
	}

	return styles
}

/**
 * Create effect css/style object from Figma effect style data
 *
 * @function
 * @name createEffectCSSFromFigmaToken
 * @memberof module:helpers/figma
 *
 * @param {Object} effectObj Figma effect data
 * @returns {Object} Style/CSS object
 */
export const createEffectCSSFromFigmaToken = (effectObj) => {
	const numberKeys = Object.keys(effectObj).filter((key) => !isNaN(key))

	if (!effectObj.value && !numberKeys.length) return null

	if (numberKeys.length > 1) {
		const style = {}
		numberKeys.forEach((key) => {
			const effect = effectObj[key]
			if (effect.value.type === effectType.BACKGROUND_BLUR) {
				style.backdropFilter = `blur(${effect.value.radius}px)`
			} else if (effect.value.type === effectType.LAYER_BLUR) {
				style.filter = `blur(${effect.value.radius}px)`
			} else if (effect.value.type === effectType.DROP_SHADOW) {
				if (style.boxShadow) {
					style.boxShadow += `, ${effect.value.offsetX}px ${effect.value.offsetY}px ${effect.value.radius}px ${effect.value.color}`
				} else {
					style.boxShadow = `${effect.value.offsetX}px ${effect.value.offsetY}px ${effect.value.radius}px ${effect.value.color}`
				}
			} else if (effect.value.type === effectType.INNER_SHADOW) {
				if (style.boxShadow) {
					style.boxShadow += `, inset ${effect.value.offsetX}px ${effect.value.offsetY}px ${effect.value.radius}px ${effect.value.color}`
				} else {
					style.boxShadow = `inset ${effect.value.offsetX}px ${effect.value.offsetY}px ${effect.value.radius}px ${effect.value.color}`
				}
			}
		})

		return style
	} else if (effectObj.value.type === effectType.BACKGROUND_BLUR) {
		return {
			backdropFilter: `blur(${effectObj.value.radius}px)`
		}
	} else if (effectObj.value.type === effectType.LAYER_BLUR) {
		return { filter: `blur(${effectObj.value.radius}px)` }
	} else if (effectObj.value.type === effectType.DROP_SHADOW) {
		return {
			boxShadow: `${effectObj.value.offsetX}px ${effectObj.value.offsetY}px ${effectObj.value.radius}px ${effectObj.value.color}`
		}
	} else if (effectObj.value.type === effectType.INNER_SHADOW) {
		return {
			boxShadow: `inset ${effectObj.value.offsetX}px ${effectObj.value.offsetY}px ${effectObj.value.radius}px ${effectObj.value.color}`
		}
	}
}

export default ({ app }, inject) => {
	inject('createGradientCSSFromFigmaToken', createGradientCSSFromFigmaToken)
	inject('formatFigmaColor', formatFigmaColor)
	inject('createEffectCSSFromFigmaToken', createEffectCSSFromFigmaToken)
	inject('effectStyles', effectStyles)
}
